import React from 'react';
import { IonCard, IonContent, IonFooter, IonModal, withIonLifeCycle } from '@ionic/react';

//	S T Y L E

import './md-cashdesk-checkout.scss';

//	L I B S

import { lib_names_shared } from '../../libs/lib.names.shared';

//	F U N C S

import { funcs_tickets } from '../../funcs/funcs.tickets';

//	S T A T E   -   P R O P S

import { props___MdCashDeskCheckoutModal, state___MdCashDeskCheckoutModal, state___MdCashDeskCheckoutModal___default } from './md-cashdesk-checkout.state';

//	T Y P E S

import { type___api___response } from '../../types/types.api-response';
import { type___ticket___validity } from '../../types/types.types';

//	S E R V I C E S

import { service_RestApiService } from '../../services/service-api';

//	C O M P O N E N T S

import ComEventComponent from '../../components/com-event/com-event';
import ComSpinnerComponent from '../../components/com-spinner/com-spinner';
import ComSpinnerButtonComponent from '../../components/com-spinner-button/com-spinner-button';
import ComTicketTypeComponent from '../../components/com-ticket-type/com-ticket-type';
import ComTicketValidComponent from '../../components/com-ticket-valid/com-ticket-valid';

//	C L A S S

class MdCashDeskCheckoutModal extends React.Component<props___MdCashDeskCheckoutModal, state___MdCashDeskCheckoutModal>
{

//#region 																							C O N F I G

	private readonly MdCashDeskCheckoutModal___modal_config: any = {
		backdropDismiss: false,
		handle: false,
		keyboardClose: false,
		showBackdrop: true,
		onDidPresent: () => { this.event___onDidPresent(); },
		onDidDismiss: () => { this.event___onDidDismiss(); }
	};

//#endregion

//#region 																							D E C L A R A T I O N S
		
	private readonly _API: service_RestApiService = new service_RestApiService();

	private readonly funcs___tickets: funcs_tickets = new funcs_tickets();

	private readonly audio___blip_valid: HTMLAudioElement = new Audio('assets/audio/blip_valid.mp3');
	private readonly audio___blip_error: HTMLAudioElement = new Audio('assets/audio/blip_error.mp3');

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___MdCashDeskCheckoutModal,
	)
	{
		super(props);
		this.state = state___MdCashDeskCheckoutModal___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	read_props___user = async () => {
		if (this.state.argument_object___ticket_pr)
		{
			const ___temp___user___props: type___api___response = await this._API.users___read___single('id', this.state.argument_object___ticket_pr);
			this.setState({
				user___props: ___temp___user___props.data,
				user___props___is_loading: false,
			});
		}
		else
		{
			this.setState({
				user___props: null,
				user___props___is_loading: false,
			});
		}
	}

	read_props___event = async () => {
		if (this.state.argument_object___ticket_event) {
			const ___temp___ticket_event_props: type___api___response = await this._API.events___read___single(this.state.argument_object___ticket_event);
			this.setState({
				event___props: ___temp___ticket_event_props.data,
				event___props___is_loading: false
			});
		}
		else
		{
			console.log('error in event');
		}
	}

	read_props___ticket = async () => {
		const ___temp___ticket_props: type___api___response = await this._API.tickets___read___single(this.props.argument_object['ticket_id']!);
		if (___temp___ticket_props && ___temp___ticket_props['response'] === 'success' && ___temp___ticket_props['data']) 
		{
			this.setState({
				argument_object___ticket_type: ___temp___ticket_props['data']['ticket_type'],
				ticket___props: ___temp___ticket_props['data'],
				ticket___props___is_loading: false,
			}, async () => {
				let ___temp___ticket_validity: type___ticket___validity = 'valid';
				if (this.state.ticket___props['ticket_used'] === true) { ___temp___ticket_validity = 'used'; }
				this.setState({
					argument_object___ticket_validity: ___temp___ticket_validity
				}, async () => {
					if (___temp___ticket_validity === 'valid')
					{
						switch (this.state.argument_object___ticket_type) {
							case 'invite':
								await this.read_props___event();
								await this.read_props___user();
							break;
							default:
							case 'online':
							case 'standard':
								await this.read_props___ticket_type();
							break;
						}
					}
				});
			});
		}
		else
		{
			this.setState({
				argument_object___ticket_validity: 'error'
			});
		}
	}

	read_props___ticket_type = async () => {
		if (this.state.argument_object___ticket_type !== null && this.state.argument_object___ticket_type !== 'invite') {
			const ___temp___ticket_type_props: type___api___response = await this._API.tickets_types___read___single(this.state.ticket___props['ticket_type_reference']);
			this.setState({
				ticket___type_props: ___temp___ticket_type_props.data,
				ticket___type_props___is_loading: false
			});
		}
	}

//#endregion

//#region 																							H A N D L E R S

	handle___button___onCheckout = async (___should_check: boolean = false) => {
		if (___should_check === true) {
			const ___temp___ticket_checkout___encrypted: string = this.funcs___tickets.ticket___encrypt___from_object(this.props.argument_object)
			const ___temp___ticket_checkout: type___api___response = await this._API.cashdesk___ticket_submit(___temp___ticket_checkout___encrypted);


			console.log('___temp___ticket_checkout', ___temp___ticket_checkout)

			if (___temp___ticket_checkout && ___temp___ticket_checkout.response === 'success') {
				if ('vibrate' in navigator) { navigator.vibrate([300]); }
				this.audio___blip_valid.play();

				
	
	
			} else {
				this.audio___blip_error.play();

	
				console.log('ads no')
	
	
	
			}
		}
		this.props.event___onDidDismiss(___should_check);
	}















//#endregion

//#region 																							L I F E C Y C L E

	event___onDidPresent() : void
	{
		if (this.props.argument_object) {
			this.setState({
				argument_object___ticket_type: this.props.argument_object['ticket_type'],
				argument_object___ticket_id: this.props.argument_object['ticket_id'],
				argument_object___ticket_event: this.props.argument_object['ticket_event'],
				argument_object___ticket_pr: this.props.argument_object['ticket_pr'],
			}, async () => {
				if (this.props.argument_object.ticket_id)
				{
					await this.read_props___ticket();
				}
				else
				{
					this.setState({
						argument_object___ticket_validity: 'valid',
					}, async () => {
						await this.read_props___event();
						await this.read_props___user();
					});
				}	
			})
		}
	}

	event___onDidDismiss() : void {
		this.setState({
			...state___MdCashDeskCheckoutModal___default
		})
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonModal isOpen={ this.props.isOpen } { ...this.MdCashDeskCheckoutModal___modal_config }>
				{(this.props.argument_object) ? <>
					
					<IonContent>
						{(() => {
							switch (this.state.argument_object___ticket_validity) {
								case 'valid': return <>
									<section className="md-cashdesk-checkout---container">
										{(() => {
											switch (this.state.argument_object___ticket_type) {
												case lib_names_shared.tickets.invite: return <>
													{(this.state.event___props___is_loading === true || this.state.user___props___is_loading === true) ? <>
														<br />
														<br />
														<br />
														<br />
														<br />
														<br />
														<br />
														<br />
														<br />
														<br />
														<ComSpinnerComponent size='standard'/>
													</> : <>
														<section className="md-cashdesk-checkout---container---invite">
															<br />
															<br />
															<ComTicketValidComponent ticket_type='invite' ticket_valid={ true }/>
															<br />
															<br />
															<br />
															<IonCard>
																<ComEventComponent event___props={ this.state.event___props }/>
															</IonCard>
															<br />
															{(this.state.user___props !== null) ? <>
																<h6>Invito inviato da</h6>
																<h4>{ this.state.user___props['user_name'] + ' ' + this.state.user___props['user_surname'] }</h4>
																<br />
															</> : <></>}
														</section>
													</>}
												</>; break;
												case lib_names_shared.tickets.standard: return <>
													{(this.state.ticket___props___is_loading === true) ? <>
														<br />
														<br />
														<br />
														<br />
														<br />
														<br />
														<br />
														<br />
														<br />
														<br />
														<ComSpinnerComponent size='standard'/>
													</> : <>
														<section className="md-cashdesk-checkout---container---ticket">
															<br />
															<ComTicketValidComponent ticket_type='ticket' ticket_valid={ true }/>
															<br />
															<br />
															<h6>Intestato a</h6>
															<h4>{ this.state.ticket___props['ticket_person_name'] }</h4>
															<br />
															<br />
															{(this.state.ticket___props['ticket_type'] === lib_names_shared.tickets.invite) ? <>
																{(this.state.user___props !== null) ? <>
																	<h6>Invito inviato da</h6>
																	<h4>{ this.state.user___props['user_name'] + ' ' + this.state.user___props['user_surname'] }</h4>
																	<br />
																</> : <></>}
															</> : <>
																{(this.state.ticket___type_props___is_loading === true) ? <>
																	<ComSpinnerButtonComponent/>
																</> : <>
																	<IonCard>
																		<ComTicketTypeComponent ticket_type___props={ this.state.ticket___type_props }/>	
																	</IonCard>
																</>}
															</>}
															<br />
															<br />
															{(this.state.ticket___props['ticket_notes'] && this.state.ticket___props['ticket_notes'].length > 0) ? <>
																<h6>Note:</h6>
																<p>{ this.state.ticket___props['ticket_notes'] }</p>
															</> : <></>} 
														</section>
													</>}
												</>; break;
											}
										})()}
									</section>
								</>; break;
								case 'used':
								case 'error': return <>
									<section className="md-cashdesk-checkout---error">
										<IonCard color="danger" onClick={() => { this.handle___button___onCheckout(false); }}>
											<br />
											<section>
												<i className="fas fa-exclamation-triangle"></i>
												<br />
												<br />
												<h1>Ticket non Valido</h1>
												<br />
												<p>
													{(() => {
														switch (this.state.argument_object___ticket_validity) {
															case 'used': return <>Questo ticket risulta già utilizzato in precedenza</>; break;
															case 'error': return <>Errore generico nella validazione del ticket</>; break;
														}
													})()}
												</p>
											</section>
											<br />
											<br />
											<br />
											<h6>clicca ovunque per chiudere</h6>
											<br />	
										</IonCard>
									</section>
								</>; break;
								default: return <>
									<br />
									<br />
									<br />
									<br />
									<br />
									<ComSpinnerComponent size="small" />
								</>; break;
							}
						})()}
					</IonContent>

					{(this.state.argument_object___ticket_validity === 'valid') ? <>
						<IonFooter>
							<section className="container---footer-section container---footer-section---check-in">
								<button className="is-element is-button is-color---danger is-full-width" onClick={() => { this.handle___button___onCheckout(false); }}>
									<h3>ANNULLA</h3>
								</button>
								{(
									(this.props.argument_object['ticket_type'] === lib_names_shared.tickets.invite && (this.state.event___props___is_loading === true || this.state.user___props___is_loading === true)) || 
									(this.props.argument_object['ticket_type'] === lib_names_shared.tickets.standard && (this.state.ticket___props___is_loading === true)) 
								) ? <>
									<button className="is-element is-button is-color---shadow is-full-width">
										<h3 style={{opacity:'0.5'}}>CARICAMENTO...</h3>
									</button>
								</> : <>
									<button className="is-element is-button is-color---success is-full-width" onClick={() => { this.handle___button___onCheckout(true); }}>
										<h3>CONVALIDA</h3>
									</button>
								</>}
							</section>
						</IonFooter>
					</> : <></>}

				</> : <>
				
				
						null
				
				
				</>}
			</IonModal>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(MdCashDeskCheckoutModal);