import { interface___modal } from "../../interfaces/interface.modals";
import { type___payment___currency, type___payment___type } from "../../types/types.types";

//	P R O P S

export interface props___MdPaymentsUsersPayModal extends interface___modal {

	user_id: string | null,
	event_id: string | null

}

//	S T A T E

export interface state___MdPaymentsUsersPayModal {

	user___id: string,
	user___props: any | null,
	user___props___is_loading: boolean,

	user_payments___returns___quantity: number,
	user_payments___returns___price: number,
	user_payments___is_loading: boolean,

	event___id: string,
	event___props: any | null,
	event___props___is_loading: boolean,

	field___payment___type: type___payment___type,
	field___payment___currency: type___payment___currency,
	field___payment___quantity: number,
	field___payment___notes: string,




	alert___askForClose: boolean,
	alert___askForSubmit: boolean,
	alert___askForSubmit___error: boolean,

	toast___isSubmitted: boolean,
	toast___isError: boolean,

	



}

//	S T A T E   D E F A U L T

export const state___MdPaymentsUsersPayModal___default: state___MdPaymentsUsersPayModal = {

	user___id: '',
	user___props: null,
	user___props___is_loading: true,

	user_payments___returns___quantity: 0,
	user_payments___returns___price: 0,
	user_payments___is_loading: false,

	event___id: '',
	event___props: null,
	event___props___is_loading: true,

	field___payment___type: 'cash',
	field___payment___currency: 'eur',
	field___payment___quantity: 0,
	field___payment___notes: '',












	alert___askForClose: false,
	alert___askForSubmit: false,
	alert___askForSubmit___error: false,

	toast___isSubmitted: false,
	toast___isError: false,

}