import React from 'react'
import { IonCard } from '@ionic/react';

//	S T Y L E

import './com-event-null.scss'

//	C L A S S

export default class ComEventNullComponent extends React.Component<{}, {}>
{

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<IonCard className="com-event-null---container">
				<h6>Nessun evento selezionato</h6>
			</IonCard>
		</>;
	}

//#endregion

}