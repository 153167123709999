import { interface___modal } from "../../interfaces/interface.modals";

//	P R O P S

export interface props___MdTicketEditTypeModal extends interface___modal {

	event_id: string
	can___haveOnlineTickets: boolean

}

//	S T A T E

export interface state___MdTicketEditTypeModal {

	ticket___type_collection: any[],
	ticket___type_collection___is_loading: boolean,

}

//	S T A T E   D E F A U L T

export const state___MdTicketEditTypeModal___default: state___MdTicketEditTypeModal = {

	ticket___type_collection: [],
	ticket___type_collection___is_loading: true,

}