import React from 'react';
import { IonAlert, IonButton, IonButtons, IonCard, IonContent, IonFooter, IonHeader, IonLabel, IonListHeader, IonModal, IonToast, IonToolbar, withIonLifeCycle } from '@ionic/react';

//	S T Y L E

import './md-payments-users-pay.scss';

//	I N T E R F A C E S

import { interface___IonAlert } from '../../interfaces/interface.alert';

//	F U N C S 

import { funcs_numbers } from '../../funcs/funcs.numbers';

//	S T A T E   -   P R O P S

import { props___MdPaymentsUsersPayModal, state___MdPaymentsUsersPayModal, state___MdPaymentsUsersPayModal___default } from './md-payments-users-pay.state';

//	T Y P E S

import { type___api___response } from '../../types/types.api-response';

//	S E R V I C E S

import { service_RestApiService } from '../../services/service-api';

//	C O M P O N E N T S

import ComEventComponent from '../../components/com-event/com-event';
import ComSpinnerComponent from '../../components/com-spinner/com-spinner';

//	C L A S S

class MdPaymentsUsersPayModal extends React.Component<props___MdPaymentsUsersPayModal, state___MdPaymentsUsersPayModal>
{

//#region 																							C O N F I G

	private readonly MdPaymentsUsersPayModal___modal_config: any = {
		breakpoints: [0, 1],
		backdropDismiss: true,
		handle: true,
		initialBreakpoint: 1,
		keyboardClose: true,
		showBackdrop: true,
		onDidPresent: () => { this.event___onDidPresent(); },
		onDidDismiss: () => { this.event___onDidDismiss(); }
	};

//#endregion

//#region 																							D E C L A R A T I O N S
		
	private readonly _API: service_RestApiService = new service_RestApiService();

	private readonly funcs___numbers: funcs_numbers = new funcs_numbers();

//#endregion

//#region 																							I N T E R F A C E S

	private readonly element___IonAlert___cancel_payment = () : interface___IonAlert => {
		return {
			header: 'Aspetta',
			message: 'Vuoi abbandonare questo pagamento?',
			buttons: [
				{
					text: 'Si',
					handler: () => {
						this.setState({
							...state___MdPaymentsUsersPayModal___default
						}, () => {
							this.props.event___onDidDismiss(null);
						});
					}
				},
				{
					text: 'Annulla',
					role: 'cancel',
					handler: () => {
						this.setState({
							alert___askForClose: false
						});
					}
				}
			]
		};
	};

	private readonly element___IonAlert___submit_payment = () : interface___IonAlert => {
		return {
			header: 'Il tuo pagamento è pronto',
			message: 'Una volta processato non sarà possibile modificarlo, vuoi continuare ed inviare il pagamento?',
			buttons: [
				{
					text: 'Si',
					handler: () => {
						this.setState({
							alert___askForSubmit: false
						}, async () => {
							await this.submit___payment();
						});
					}
				},
				{
					text: 'Annulla',
					role: 'cancel',
					handler: () => {
						this.setState({
							alert___askForSubmit: false
						});
					}
				}
			]
		};
	};

	private readonly element___IonAlert___submit_payment___error = () : interface___IonAlert => {
		return {
			header: 'Errore',
			message: 'Non puoi creare un pagamento del valore di 0, seleziona almeno un rientro da pagare',
			buttons: [
				{
					text: 'Ok, ho capito',
					role: 'cancel',
					handler: () => {
						this.setState({
							alert___askForSubmit___error: false
						});
					}
				}
			]
		};
	};

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___MdPaymentsUsersPayModal,
	)
	{
		super(props);
		this.state = state___MdPaymentsUsersPayModal___default;
	}

//#endregion

//#region 																							R E A D   P R O P S


	read_props___event = async () => {
		const ___temp___event___props: type___api___response = await this._API.events___read___single(this.state.event___id!);
		if (!___temp___event___props || ___temp___event___props.response !== 'success' || ___temp___event___props.data === null) { console.log('404'); }
		this.setState({
			event___props: ___temp___event___props.data,
			event___props___is_loading: false,
		});	
	}

	read_props___user = async () => {
		const ___temp___user___props: type___api___response = await this._API.users___read___single('id', this.state.user___id!);
		if (!___temp___user___props || ___temp___user___props.response !== 'success' || ___temp___user___props.data === null) { console.log('404'); }
		this.setState({
			user___props: ___temp___user___props.data,
			user___props___is_loading: false,
		});
	}

	read_props___user_payments = async () => {
		const ___temp___user_payment_props: type___api___response = await this._API.payments_users___read___single(this.props.user_id!, this.props.event_id)
		if (!___temp___user_payment_props || ___temp___user_payment_props.response !== 'success' || ___temp___user_payment_props.data === null) { console.log('404'); }
		
		// no event case
		
		this.setState({
			user_payments___returns___price: ___temp___user_payment_props.data['user_tickets'][0]['ticket_payment_rate'],
			user_payments___returns___quantity: ___temp___user_payment_props.data['user_tickets'][0]['ticket_count_unpayed'],
			user_payments___is_loading: false,
		});
	}

//#endregion

//#region 																							H A N D L E R S

	handle___payment___inc_dec = (___action: 'decrease' | 'increase') => {
		let ___temp___user_payments___returns___quantity___to_pay: number = this.state.field___payment___quantity;
		switch (___action) {
			case 'decrease': ___temp___user_payments___returns___quantity___to_pay -= 1; break;
			case 'increase': ___temp___user_payments___returns___quantity___to_pay++; break;
		}
		this.setState({
			field___payment___quantity: ___temp___user_payments___returns___quantity___to_pay
		});
	}

	handle___MdPaymentsUsersPayModal___askForClose = () => {
		this.setState({
			alert___askForClose: true
		});
	}

	handle___MdPaymentsUsersPayModal___askForSubmit = () => {
		if (this.state.field___payment___quantity > 0) {
			this.setState({
				alert___askForSubmit: true
			});
		} else {
			this.setState({
				alert___askForSubmit___error: true
			});
		}
	}

//#endregion

//#region 																							S U B M I T

	submit___payment = async () => {
		if (this.state.field___payment___quantity > 0)
		{
			const ___temp___payment_submitted: type___api___response = await this._API.payments_users___submit(this.state.field___payment___type, this.state.user___id, this.state.event___id, this.state.field___payment___quantity, this.state.user_payments___returns___price, this.state.field___payment___notes);
			if (___temp___payment_submitted && ___temp___payment_submitted['response'] === 'success') {
				this.setState({
					toast___isSubmitted: true,
				}, () => {
					this.props.event___onDidDismiss(true);
				});
			} else {
				this.setState({
					toast___isError: true,
				}, () => {
					this.props.event___onDidDismiss(false);
				});
			}
		}
	}

//#endregion

//#region 																							L I F E C Y C L E

	event___onDidPresent() : void
	{
		if (this.props.user_id !== null && this.props.event_id !== null)
		{
			this.setState({
				user___id: this.props.user_id!,
				event___id: this.props.event_id!,
			}, async () => {
				await this.read_props___event();
				await this.read_props___user();
				await this.read_props___user_payments();
			});
		}
		else
		{
			this.props.event___onDidDismiss();
		}
	}

	event___onDidDismiss() : void
	{
		this.setState({
			...state___MdPaymentsUsersPayModal___default
		}, () => {
			this.props.event___onDidDismiss();
		});
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{

		return <>

			<IonAlert isOpen={ this.state.alert___askForClose } { ...(this.element___IonAlert___cancel_payment()) }></IonAlert>
			<IonAlert isOpen={ this.state.alert___askForSubmit } { ...(this.element___IonAlert___submit_payment()) }></IonAlert>
			<IonAlert isOpen={ this.state.alert___askForSubmit___error } { ...(this.element___IonAlert___submit_payment___error()) }></IonAlert>

			<IonToast position="top" isOpen={ this.state.toast___isSubmitted } message="Hai effettuato il pagamento correttamente" color="success" duration={ 3000 } onDidDismiss={() => { this.setState({ toast___isSubmitted: false }); }}></IonToast>
			<IonToast position="top" isOpen={ this.state.toast___isError } message="Errore nel completamento del pagamento, riprova più tardi o contatta l'assistenza..." color="danger" duration={ 3000 } onDidDismiss={() => { this.setState({ toast___isError: false }); }}></IonToast>

			<IonModal isOpen={ this.props.isOpen } { ...this.MdPaymentsUsersPayModal___modal_config }>

				<IonHeader className="ion-no-border" collapse="fade">
					<IonToolbar>
						<IonButtons slot="end">
							<IonButton onClick={ this.handle___MdPaymentsUsersPayModal___askForClose }>annulla</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				
				<IonContent>

					{(this.state.user___props___is_loading === true || this.state.user_payments___is_loading === true || this.state.event___props___is_loading === true) ? <>
						<IonListHeader>
							<IonLabel style={{textAlign:'center'}}>
								<span style={{opacity:0.5}}>Nuovo pagamento ritorni a </span>
								<br />
								<span>....</span>
							</IonLabel>
						</IonListHeader>
						<br />
						<br />
						<br />
						<br />
						<br />
						<ComSpinnerComponent size="small"/>
					</> : <>

						<IonListHeader>
							<IonLabel style={{textAlign:'center'}}>
								<span style={{opacity:0.5}}>Nuovo pagamento ritorni a </span>
								<br />
								{ this.state.user___props['user_name'] + ' ' + this.state.user___props['user_surname'] }
								{(this.state.user___props['user_name_displayed'] && this.state.user___props['user_name_displayed'].length > 0) ? <>
									<span style={{opacity:'0.5',fontWeight:'bold'}}> ({ this.state.user___props['user_name_displayed'] })</span>
								</> : <></>}
							</IonLabel>
						</IonListHeader>
						
						<section className="md-payments-users-pay---container">

							<br />

							<p className="input---nb-label" style={{textAlign:'center'}}>I ritorni vengono pagati per l'evento</p>
							<IonCard>
								<ComEventComponent event___props={ this.state.event___props} />
							</IonCard>

							<br />

							<IonCard>
								<div className="md-payments-users-pay---slider">
									<div>
										{(this.state.field___payment___quantity <= 0) ? <><i className="fas fa-minus" style={{opacity:'0.5'}}></i></> : <><i className="fas fa-minus" onClick={() => { this.handle___payment___inc_dec('decrease'); }}></i></>}
										<span>
											<span>{ this.state.field___payment___quantity }</span>
											<span> / { this.state.user_payments___returns___quantity }</span>
										</span>
										{(this.state.field___payment___quantity >= this.state.user_payments___returns___quantity) ? <><i className="fas fa-plus" style={{opacity:'0.5'}}></i></> : <><i className="fas fa-plus" onClick={() => { this.handle___payment___inc_dec('increase'); }}></i></>}
									</div>
									<div>
										<input type="range" value={ this.state.field___payment___quantity } step={ 1 } min={ 1 } max={ this.state.user_payments___returns___quantity } onInput={(___e: any) => { this.setState({ field___payment___quantity: ___e.target.value }); }}/>
									</div>
									<div>
										<span>Totale dovuto = </span>
										<span>{ this.funcs___numbers.number___format_price((this.state.field___payment___quantity * this.state.user_payments___returns___price), ' €') }</span>
									</div>
								</div>
							</IonCard>

							<br />

							<label htmlFor="field---event-description">
								<p className="input---nb-label" style={{textAlign:'center'}}>Note sul Pagamento</p>
								<textarea id="field---event-description" className="input---nb-outlined" placeholder="Note sul pagamento..." style={{height:'150pt'}} value={ this.state.field___payment___notes } onInput={(___e: any) => { this.setState({ field___payment___notes: ___e.target.value}); }}></textarea>
							</label>

						</section>
					</>}

				</IonContent>
				
				<IonFooter>
					<section className="container---footer-section">
						<button className="is-element is-button is-color---folly is-full-width" onClick={ this.handle___MdPaymentsUsersPayModal___askForSubmit }>
							<i className="fa-solid fa-check"></i>
							<h4>Effettua Pagamento</h4>
						</button>
					</section>
				</IonFooter>

			</IonModal>

		</>;

	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(MdPaymentsUsersPayModal);