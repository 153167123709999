import React from 'react';
import { IonButton, IonButtons, IonContent, IonHeader, IonModal, IonToolbar } from '@ionic/react';

//	S T Y L E

import './md-info-eula.scss';

//	S T A T E S   -   P R O P S

import { props___MdInfoEulaModal } from './md-info-eula.state';

//	C O M P O N E N T S

import ComMarkdownComponent from '../../components/com-markdown/com-markdown';

//	C L A S S

export default class MdInfoEulaModal extends React.Component<props___MdInfoEulaModal, {}>
{

//#region 																							D E C L A R A T I O N S

	private readonly MdInfoEulaModal___modal_config: any = {
		breakpoints: [0, 1],
		backdropDismiss: true,
		handle: true,
		initialBreakpoint: 1,
		keyboardClose: true,
		showBackdrop: true,
	};

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{

		return <>

			<IonModal isOpen={ this.props.isOpen } { ...this.MdInfoEulaModal___modal_config }>

				<IonHeader className="ion-no-border" collapse="fade">
					<IonToolbar>
						<IonButtons slot="end">
							<IonButton onClick={ this.props.event___onDidDismiss }>chiudi</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent>

					<section className="md-info-eula---container">

						<ComMarkdownComponent file_path="/assets/docs/eula.it-it.md"/>







					</section>

				</IonContent>

			</IonModal>

		</>;

	}

//#endregion

}