//	S T A T E

export interface state___SbSettingsProfilePage {

	profile___is_editing: boolean,

	user___props: any | null,
	user___props___is_loading: boolean,

	field___user___name___displayed: string,
	field___user___name: string,
	field___user___surname: string,
	
	field___user___contact_email: string,
	field___user___contact_phone: string,

	









}

//	S T A T E   D E F A U L T

export const state___SbSettingsProfilePage___default: state___SbSettingsProfilePage = {

	profile___is_editing: false,

	user___props: null,
	user___props___is_loading: true,

	field___user___name___displayed: '',
	field___user___name: '',
	field___user___surname: '',

	field___user___contact_email: '',
	field___user___contact_phone: '',







}