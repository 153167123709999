import React from 'react';
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonToolbar, withIonLifeCycle } from '@ionic/react';
import packageJson from '../../../../package.json';

//	S T Y L E

import './sb-info-app.scss';

//	S T A T E   -   P R O P S

import { state___SbInfoAppPage, state___SbInfoAppPage___default } from './sb-info-app.state';

//	C L A S S

class SbInfoAppPage extends React.Component<{}, state___SbInfoAppPage>
{

//#region 																							C O N S T R U C T O R

	constructor(
		public props: {},
	)
	{
		super(props);
		this.state = state___SbInfoAppPage___default;
	}

//#endregion

//#region 																							V E R S I O N

	version___get___version()
	{
		const ___version: string = packageJson['version'];
		this.setState({
			version___version: ___version
		});
	}

	version___get___build()
	{
		const ___package: string = packageJson.toString();
		const ___package___hash: string = btoa(___package.toString())
		const ___package___hash___digits: string = ___package___hash.replace(/\D/g, '');
		const ___package___hash___digits___buils: string =___package___hash___digits.slice(0, 5);
		this.setState({
			version___build: ___package___hash___digits___buils
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	ionViewWillEnter() : void
	{
		setTimeout(() => {
			this.version___get___version();
			this.version___get___build();
		}, 500);
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{

		return <>
			<IonPage>

				<IonHeader className="ion-no-border" collapse="fade">
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/settings"></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent>
					<section className="sb-info-app---container">
						<div>
							<img src="/assets/icons/apple-icon.png" />
						</div>
						<section>
							<img src="/assets/logo-horizontal.svg" />
							<p>versione { this.state.version___version }</p>
							<p>build { this.state.version___build }</p>
						</section>
					</section>
				</IonContent>

			</IonPage>
		</>;

	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbInfoAppPage);