import { interface___modal } from "../../interfaces/interface.modals";
import { type___ticket___object, type___ticket___type } from "../../types/types.types";

//	P R O P S

export interface props___MdCashDeskCheckoutModal extends interface___modal {

	argument_object: type___ticket___object,

}

//	S T A T E

export interface state___MdCashDeskCheckoutModal {

	argument_object___ticket_validity: 'valid' | 'used' | 'error' | null,
	argument_object___ticket_type: type___ticket___type | null,
	argument_object___ticket_id: string | null,
	argument_object___ticket_event: string | null,
	argument_object___ticket_pr: string | null,

	user___props: any | null,
	user___props___is_loading: boolean,

	ticket___props: any | null,
	ticket___props___is_loading: boolean,

	ticket___type_props: any | null,
	ticket___type_props___is_loading: boolean,

	event___props: any | null,
	event___props___is_loading: boolean,

}

//	S T A T E   D E F A U L T

export const state___MdCashDeskCheckoutModal___default: state___MdCashDeskCheckoutModal = {
	
	argument_object___ticket_validity: null,
	argument_object___ticket_type: null,
	argument_object___ticket_id: null,
	argument_object___ticket_event: null,
	argument_object___ticket_pr: null,

	user___props: null,
	user___props___is_loading: true,

	ticket___props:  null,
	ticket___props___is_loading: true,

	ticket___type_props: null,
	ticket___type_props___is_loading: true,

	event___props: null,
	event___props___is_loading: true,

}