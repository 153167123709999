import { RouteComponentProps } from "react-router";
import { interface___page } from "../../../interfaces/interface.page";
import { type___ticket___type } from "../../../types/types.types";

//	P R O P S

export interface props___SbEventsViewPage extends RouteComponentProps<{ id: string; }>, interface___page {

}

//	S T A T E

export interface state___SbEventsViewPage {

	event___id___selected: string | null,
	event___id: string,
	event___props: any | null,

	event___tickets_collection: any[],

	ticket___is_creating: boolean,
	ticket___is_creating___type: type___ticket___type | null,

	ticket_created_preview___visible: boolean,
	ticket_created_preview___type: type___ticket___type,
	ticket_created_preview___id: string | null,

	actionSheet___set_event_as_main___visible: boolean,

}

//	S T A T E   D E F A U L T

export const state___SbEventsViewPage___default: state___SbEventsViewPage = {

	event___id___selected: null,
	event___id: '',
	event___props: null,

	event___tickets_collection: [],

	ticket___is_creating: false,
	ticket___is_creating___type: null,

	ticket_created_preview___visible: false,
	ticket_created_preview___type: 'standard',
	ticket_created_preview___id: null,

	actionSheet___set_event_as_main___visible: false,

}