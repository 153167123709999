import React from 'react'

//	S T Y L E

import './com-spinner-button.scss'

//	C L A S S

export default class ComSpinnerButtonComponent extends React.Component<{}, {}>
{
//#region 																							R E N D E R

	render() : React.ReactNode
	{

		return <>
			<div className="com-spinner-button---component">
				<i className="fa-solid fa-circle-notch"></i>
			</div>
		</>;

	}

//#endregion

}