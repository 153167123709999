import React from 'react';
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonToolbar } from '@ionic/react';

//	S T Y L E

import './sb-info-eula.scss';

//	C O M P O N E N T S

import ComMarkdownComponent from '../../../components/com-markdown/com-markdown';

//	C L A S S

export default class SbInfoEulaPage extends React.Component<{}, {}>
{

//#region 																							R E N D E R

	render() : React.ReactNode
	{

		return <>

			<IonPage>

				<IonHeader className="ion-no-border" collapse="fade">
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/settings"></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent>

					<section className="sb-info-eula---container">

						<ComMarkdownComponent file_path="/assets/docs/eula.it-it.md"/>







					</section>

				</IonContent>

			</IonPage>

		</>;

	}

//#endregion

}