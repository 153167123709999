import React from 'react';
import { IonBackButton, IonButtons, IonCard, IonContent, IonFooter, IonHeader, IonLabel, IonListHeader, IonPage, IonToolbar, withIonLifeCycle } from '@ionic/react';

//	S T Y L E

import './sb-events-import.scss';

//	S T A T E   -   P R O P S

import { props___SbEventsEditPage, state___SbEventsEditPage, state___SbEventsEditPage___default } from './sb-events-import.state';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';

//	S E R V I C E S

import { service_RestApiService } from '../../../services/service-api';
import { service_Router } from '../../../services/service-router';

//	C O M P O N E N T S

import ComEventComponent from '../../../components/com-event/com-event';
import ComSpinnerComponent from '../../../components/com-spinner/com-spinner';
import ComSpinnerButtonComponent from '../../../components/com-spinner-button/com-spinner-button';

//	C L A S S

class SbEventsImportPage extends React.Component<props___SbEventsEditPage, state___SbEventsEditPage>
{

//#region 																							D E C L A R A T I O N S
		
	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _ROUTER: service_Router = new service_Router();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbEventsEditPage,
	) {
		super(props);
		this.state = state___SbEventsEditPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	read_props___event = async () => {
		const ___temp___event___props: type___api___response = await this._API.events___read___single___preview(this.state.event___id!);
		if (!___temp___event___props || ___temp___event___props.response !== 'success' || ___temp___event___props.data === null) { console.log('404'); }
		this.setState({
			event___props: ___temp___event___props.data,
			event___props___is_loading: false
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	submit___event_connection = async () => {
		const ___event___is_connected: type___api___response = await this._API.events___connect(this.state.event___id, this.state.field___auth_code);
		if (!(___event___is_connected && ___event___is_connected.response === 'success')) {
			alert('Errore nella connessione dell\'evento \n\nProbabilmente il codice di autenticazione è sbagliato o il link che hai seguito non è più disponibile \n\nSe l\'errore persiste contattare l\'assistenza');
		} else {
			window.location.href = '/dashboard';
		}
	}

//#endregion

//#region 																							L I F E C Y C L E

	ionViewDidEnter() : void {
		const ___event___id: string = this._ROUTER.get_id(this.props);
		this.setState({
			event___id: ___event___id,
		}, async () => {
			await this.read_props___event();
		});
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				<IonHeader className="ion-no-border" collapse="fade">
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/dashboard"></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent>
					{(this.state.event___props___is_loading === true) ? <>
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						<ComSpinnerComponent size="small"/>
					</> : <>
						<div className="sb-events-import---container">
						
							<IonListHeader>
								<IonLabel>Importa Evento da Nearby</IonLabel>
							</IonListHeader>
						
							<br />
						
							<section>

								<IonCard>
									<ComEventComponent event___props={ this.state.event___props }/>
								</IonCard>

								<br />

								<label htmlFor="field---auth-code">
									<p className="input---nb-label is-required">Codice di autenticazione</p>
									<input type="text" id="field---auth-code" className="input---nb-outlined" placeholder="000000" value={ this.state.field___auth_code } onInput={(___e: any) => { this.setState({ field___auth_code: ___e.target.value}); }}/>
								</label>

							</section>
			
							<br />
							<br />
							<br />

						</div>
					</>}
				</IonContent>

				<IonFooter>
					{(this.state.event___props___is_loading !== false) ? <>
						<ComSpinnerButtonComponent/>
					</> : <>
						<section className="container---footer-section" onClick={ this.submit___event_connection }>
							<button className="is-element is-button is-color---folly is-full-width">
								<i className="fa-solid fa-check"></i>
								<h6>Importa Evento</h6>
							</button>
						</section>
					</>}
				</IonFooter>

			</IonPage>
		</>;
	}

//#endregion

}

//	E X P O R T 

export default withIonLifeCycle(SbEventsImportPage);