import { interface___page } from "../../../interfaces/interface.page";

//	P R O P S

export interface props___SbPaymentsUsersPage extends interface___page {

}

//	S T A T E

export interface state___SbPaymentsUsersPage {


	payments_users___props: any | null,
	payments_users___props___order: 'standard',
	payments_users___props___is_loading: boolean,








}

//	S T A T E   D E F A U L T

export const state___SbPaymentsUsersPage___default: state___SbPaymentsUsersPage = {

	payments_users___props: null,
	payments_users___props___order: 'standard',
	payments_users___props___is_loading: true,





	






}