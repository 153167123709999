import { RouteComponentProps } from "react-router";
import { interface___page } from "../../../interfaces/interface.page";

//	P R O P S

export interface props___SbEventsViewPage extends RouteComponentProps<{ id: string; }>, interface___page {

}

//	S T A T E

export interface state___SbEventsViewPage {

	event___id: string,
	event___props: any | null,
	event___props___is_loading: boolean,

	event_analytics___chart___props: any | null,
	event_analytics___chart___is_loading: boolean,
	
	tickets_analytics___props: any | null,
	tickets_analytics___props___is_loading: boolean,








}

//	S T A T E   D E F A U L T

export const state___SbEventsViewPage___default: state___SbEventsViewPage = {

	event___id: '',
	event___props: null,
	event___props___is_loading: true,

	event_analytics___chart___props: null,
	event_analytics___chart___is_loading: true,

	tickets_analytics___props: null,
	tickets_analytics___props___is_loading: true,








}