import React from 'react'
import { IonBadge, IonCard, IonItem, IonLabel } from '@ionic/react';

//	S T Y L E

import './com-ticket-type.scss'

//	S T A T E S   -   P R O P S

import { props___ComTicketTypeComponent } from './com-ticket-type.state';

//	F U N C S

import { funcs_numbers } from '../../funcs/funcs.numbers';

//	C L A S S

export default class ComTicketTypeComponent extends React.Component<props___ComTicketTypeComponent, {}>
{

//#region 																							D E C L A R A T I O N S

	private readonly funcs___numbers: funcs_numbers = new funcs_numbers();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComTicketTypeComponent,
	)
	{
		super(props);
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{

		return <>
			
			<IonCard>
				<IonItem>
					<IonLabel><b>{ this.props.ticket_type___props['ticket_type_name'] }</b></IonLabel>
					{(() => {
						switch (this.props.ticket_type___props['ticket_type']) {
							case 'standard': return <>
								<IonBadge slot="end">{ this.funcs___numbers.number___format_price(this.props.ticket_type___props['ticket_type_price'], '€') }</IonBadge>
							</>; break;
							case 'online': return <>
								<IonBadge slot="end">SOLO ONLINE</IonBadge>
							</>; break;
						}
					})()}
				</IonItem>
				{(this.props.show___description !== false) ? <>
					{(this.props.ticket_type___props['ticket_type_description'] && this.props.ticket_type___props['ticket_type_description'].length > 0) ? <>
						<p className="com-ticket-type---description">{ this.props.ticket_type___props['ticket_type_description'] }</p>
					</> : <></>}
				</> : <></>}
			</IonCard>
		</>;

{/**

@Prop() _id: Types.ObjectId;

@Prop() : type___ticket___type;

@Prop() : string;
@Prop() : string;

@Prop() : string;
@Prop() ticket_type_quantity_available: string;

@Prop() ticket_type_organization: Types.ObjectId;
@Prop() ticket_type_event: Types.ObjectId;


 */}
					

	}

//#endregion

}