import { type___ticket___type } from "../../types/types.types"

//	P R O P S

export interface props___ComButtonTicketShareComponent {

	ticket_type: type___ticket___type,
	ticket_code: string,
	ticket_event_props: any | null,

	ticket_dom_reference: any,

}

//	S T A T E

export interface state___ComButtonTicketShareComponent {

	navigator___title: string,
	navigator___text: string,
	navigator___url: string,

	actionSheet___ticket_share___visibile: boolean,

}

//	S T A T E   D E F A U L T

export const state___ComButtonTicketShareComponent___default: state___ComButtonTicketShareComponent = {

	navigator___title: '',
	navigator___text: '',
	navigator___url: '',

	actionSheet___ticket_share___visibile: false,

}