//	P R O P S

export interface props___SbSettingsAuthenticationPage {

}

//	S T A T E

export interface state___SbSettingsAuthenticationPage {

	field___password___old: string, 
	field___password___new___x1: string,
	field___password___new___x2: string,

	toast___password_change___active: boolean,
	toast___password_change___type: 'success' | 'error_generic' | 'error_new_not_match' | 'error_old_wrong' | null,

}

//	S T A T E   D E F A U L T

export const state___SbSettingsAuthenticationPage___default: state___SbSettingsAuthenticationPage = {

	field___password___old: '', 
	field___password___new___x1: '',
	field___password___new___x2: '',

	toast___password_change___active: false,
	toast___password_change___type: null,

}