import { RouteComponentProps } from "react-router";
import { interface___page } from "../../../interfaces/interface.page";

//	P R O P S

export interface props___SbEventsListPage extends RouteComponentProps<{ id: string; }>, interface___page {
	
	id?: string

}

//	S T A T E

export interface state___SbEventsListPage {

	event___id___selected: string | null,

	event___collection: any[],
	event___collection___loading: boolean,

	actionSheet___event_options___target: any | null,
	actionSheet___event_options: boolean,

}

//	S T A T E   D E F A U L T

export const state___SbEventsListPage___default: state___SbEventsListPage = {

	event___id___selected: null,

	event___collection: [],
	event___collection___loading: true,

	actionSheet___event_options___target: null,
	actionSheet___event_options: false,

}