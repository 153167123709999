import React from 'react'

//	S T Y L E

import './com-spinner-refresh.scss'

//	C L A S S

export default class ComSpinnerRefreshComponent extends React.Component<{}, {}>
{
//#region 																							R E N D E R

	render() : React.ReactNode
	{

		return <>
			<div className="com-spinner-refresh---component">
				<i className="fa-solid fa-arrows-rotate"></i>
			</div>
		</>;

	}

//#endregion

}