import React from 'react';
import { IonBackButton, IonButtons, IonCard, IonContent, IonHeader, IonItem, IonLabel, IonList, IonListHeader, IonPage, IonToolbar } from '@ionic/react';

//	S T Y L E

import './sb-info-licences.scss';

//	C L A S S

export default class SbInfoLicencesPage extends React.Component<{}, {}>
{

//#region 																							R E N D E R

	render() : React.ReactNode
	{

		return <>

			<IonPage>

				<IonHeader className="ion-no-border" collapse="fade">
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/settings"></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent>

					<section className="sb-info-licences---container">

						<IonListHeader>
							<IonLabel>Licenze di terze parti</IonLabel>
						</IonListHeader>
						
						<br/>

						<IonCard>
							<IonList>
								<IonItem target="_blank" className="is-ion-item-button" href="https://www.apple.com/it/legal/sla/">
									<img src="../../assets/libraries/apple.svg"/>
									<IonLabel>Apple</IonLabel>
								</IonItem>
								<IonItem target="_blank" className="is-ion-item-button" href="https://www.apple.com/legal/internet-services/maps/terms-en.html/">
									<img src="../../assets/libraries/applemaps.svg"/>
									<IonLabel>Apple Maps</IonLabel>
								</IonItem>
							</IonList>
						</IonCard>

						<br />

						<IonCard>
							<IonList>
								<IonItem target="_blank" className="is-ion-item-button" href="https://www.freepikcompany.com/legal">
									<img src="../../assets/libraries/flaticon.svg"/>
									<IonLabel>FlatIcon</IonLabel>
								</IonItem>
							</IonList>
						</IonCard>

						<br />

						<IonCard>
							<IonList>
								<IonItem target="_blank" className="is-ion-item-button" href="https://policies.google.com/">
									<img src="../../assets/libraries/google.svg"/>
									<IonLabel>Google</IonLabel>
								</IonItem>
								<IonItem target="_blank" className="is-ion-item-button" href="https://cloud.google.com/maps-platform/terms">
									<img src="../../assets/libraries/googlemaps.svg"/>
									<IonLabel>Google Maps</IonLabel>
								</IonItem>
							</IonList>
						</IonCard>

						<br />

						<IonCard>
							<IonList>
								<IonItem target="_blank" className="is-ion-item-button" href="https://fontawesome.com/license">
									<img src="../../assets/libraries/fontawesome.svg"/>
									<IonLabel>Font Awesome</IonLabel>
								</IonItem>
							</IonList>
						</IonCard>

						<br />

						<IonCard>
							<IonList>
								<IonItem target="_blank" className="is-ion-item-button" href="https://www.mapbox.com/legal/tos">
									<img src="../../assets/libraries/mapbox.svg"/>
									<IonLabel>Mapbox</IonLabel>
								</IonItem>
							</IonList>
						</IonCard>

						<br/>
						<br/>

					</section>

				</IonContent>

			</IonPage>

		</>;

	}

//#endregion

}