import React from 'react';
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonToolbar } from '@ionic/react';

//	S T Y L E

import './sb-info-copyright.scss';

//	C O M P O N E N T S

import ComCopyrightComponent from '../../../components/com-copyright/com-copyright';

//	C L A S S

export default class SbInfoCopyrightPage extends React.Component<{}, {}>
{

//#region 																							R E N D E R

	render() : React.ReactNode
	{

		return <>

			<IonPage>

				<IonHeader className="ion-no-border" collapse="fade">
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/settings"></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent>

					<section className="sb-info-copyright---container">

						<img src="/assets/logo-horizontal.svg" />
						<br/>
						<br/>
						<br/>
						<br/>
						<br/>
						<ComCopyrightComponent/>
						<br/>
						<p>
							L'app, il sito web ed i relativi contenuti sono ideati, progettati, sviluppati, gestiti ed amministrati da <b>Prysm Lab</b>, 
							la cui sede legale è situata in Terralba (Or), alla via Azuni, civ. 27, CAP 09098, con domicilio digitale prysmlab{ '@' }pec.it, 
							numero REA OR-139267, D-U-N-S® Number 438059184, codice fiscale e numero di iscrizione al Registro delle Imprese di Oristano 01212540957
						</p>
					</section>

				</IonContent>

			</IonPage>

		</>;

	}

//#endregion

}