import { RouteComponentProps } from "react-router";
import { type___api___response } from "../../../types/types.api-response";

//	P R O P S

export interface props___SbUsersEditPage extends RouteComponentProps<{ id: string; }> {
	
	id?: string

}

//	S T A T E

export interface state___SbUsersEditPage {

	organization___props: any | null,
	organization___props___is_loaded: boolean,

	user___id: string | null,
	user___props: any | null,
	user___props___is_loading: boolean,

	field___user___username: string,
	field___user___name___displayed: string,
	field___user___name: string,
	field___user___surname: string,
	
	field___user___contact_email: string,
	field___user___contact_phone: string,

	field___user___role_is___admin: boolean,
	field___user___role_is___cashdesk: boolean,
	field___user___role_is___pr: true,

	alert___delete_user___visibility: boolean,

	toast___user___is_submitted: type___api___response['response'],

}

//	S T A T E   D E F A U L T

export const state___SbUsersEditPage___default: state___SbUsersEditPage = {

	organization___props: null,
	organization___props___is_loaded: true,

	user___id: null,
	user___props: null,
	user___props___is_loading: true,

	field___user___username: '',
	field___user___name___displayed: '',
	field___user___name: '',
	field___user___surname: '',

	field___user___contact_email: '',
	field___user___contact_phone: '',

	field___user___role_is___admin: false,
	field___user___role_is___cashdesk: false,
	field___user___role_is___pr: true,

	alert___delete_user___visibility: false,

	toast___user___is_submitted: 'no-data'

}