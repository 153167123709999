import React from 'react'
import { IonBadge, IonCard, IonItem, IonLabel, IonList, IonNote } from '@ionic/react';

//	S T Y L E

import './com-payment-user.scss'

//	T Y P E S

import { type___payment___type } from '../../types/types.types';

//	S T A T E S   -   P R O P S

import { props___ComPaymentUserComponent } from './com-payment-user.state';

//	F U N C S

import { funcs_datetime } from '../../funcs/funcs.datetime';
import { funcs_numbers } from '../../funcs/funcs.numbers';

//	C L A S S

export default class ComPaymentUserComponent extends React.Component<props___ComPaymentUserComponent, {}>
{

//#region 																							C O N S T R U C T O R

	private readonly funcs___datetime: funcs_datetime = new funcs_datetime();
	private readonly funcs___numbers: funcs_numbers = new funcs_numbers();

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<IonCard className="com-payment-user---container">
				<IonList>
					<IonItem>
						<IonLabel>
						{(() => {
							switch (this.props.payment___props['payment_type'] as type___payment___type) {
								case 'cash': return <><b>Pagamento in contanti</b></>; break;
							}
						})()}
						</IonLabel>
						<IonNote color="dark" style={{fontSize:'10pt',fontWeight:'bold'}}>
							<> il </>
							<span>{ this.funcs___datetime.datetime___get___date(this.props.payment___props['payment_done_datetime']) }</span>
							<> alle </>
							{ this.funcs___datetime.datetime___get___time(this.props.payment___props['payment_done_datetime']) }
						</IonNote>
					</IonItem>
					<IonItem>
						<IonLabel>Pagati <b>{ this.props.payment___props['payment_quantity'] }</b> rientri a <b>{ this.funcs___numbers.number___format_price(this.props.payment___props['payment_unit_price'], ' €') }</b></IonLabel>
						<IonBadge>{ this.funcs___numbers.number___format_price((this.props.payment___props['payment_quantity'] * this.props.payment___props['payment_unit_price']), ' €') }</IonBadge>
					</IonItem>
					{(this.props.payment___props['payment_notes'] && this.props.payment___props['payment_notes'].length > 0) ? <>
						<p className="com-payment-user---notes">{ this.props.payment___props['payment_notes'] }</p>
					</> : <></>}
				</IonList>
			</IonCard>
		</>;
	}

}