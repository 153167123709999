import React from 'react';
import { IonActionSheet, IonBackButton, IonButtons, IonCard, IonContent, IonHeader, IonItem, IonLabel, IonList, IonPage, IonRefresher, IonToolbar, RefresherEventDetail, withIonLifeCycle } from '@ionic/react';

//	S T Y L E

import './sb-events-view.scss';

//	L I B S

import { lib_names } from '../../../libs/lib.names';

//	S T A T E   -   P R O P S

import { props___SbEventsViewPage, state___SbEventsViewPage, state___SbEventsViewPage___default } from './sb-events-view.state';

//	I N T E R F A C E S

import { interface___IonActionSheet } from '../../../interfaces/interface.actionsheet';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';
import { type___ticket___type } from '../../../types/types.types';

//	S E R V I C E S

import { service_RestApiService } from '../../../services/service-api';
import { service_Router } from '../../../services/service-router';

//	C O M P O N E N T S

import ComEventSelectedComponent from '../../../components/com-event-selected/com-event-selected';
import ComEventTextComponent from '../../../components/com-event-text/com-event-text';
import ComEventTimeScheduleComponent from '../../../components/com-event-timeschedule/com-event-timeschedule';
import ComSpinnerComponent from '../../../components/com-spinner/com-spinner';
import ComSpinnerRefreshComponent from '../../../components/com-spinner-refresh/com-spinner-refresh';

//	M O D A L S

import MdTicketEditModal from '../../../modals/md-ticket-edit/md-ticket-edit';
import MdTicketViewModal from '../../../modals/md-ticket-view/md-ticket-view';

//	C L A S S

class SbEventsViewPage extends React.Component<props___SbEventsViewPage, state___SbEventsViewPage>
{

//#region 																							D E C L A R A T I O N S
		
	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _ROUTER: service_Router = new service_Router();

//#endregion

//#region 																							I N T E R F A C E S

	private readonly element___IonActionSheet___props: interface___IonActionSheet = {
		buttons: [
			{
				text: 'Si, seleziona',
				handler: () => { 
					const ___new_event_actual: string = this.state.event___id;
					localStorage.setItem(lib_names.localStorage.user___event_selected, ___new_event_actual);
					this.setState({
						event___id___selected: localStorage.getItem(lib_names.localStorage.user___event_selected),
					});
				}
			},
			{
				text: 'Annulla',
				role: 'cancel',
			}
		]
	};

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbEventsViewPage,
	) {
		super(props);
		this.state = state___SbEventsViewPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	read_props___event = async () => {
		const ___temp___event___props: type___api___response = await this._API.events___read___single(this.state.event___id);
		if (!___temp___event___props || ___temp___event___props.response !== 'success' || ___temp___event___props.data === null) { alert(JSON.stringify(___temp___event___props)) }
		this.setState({
			event___props: ___temp___event___props.data
		}, async () => {
			await this.read_props___tickets_types();
		});
	}

	read_props___tickets_types = async () => {
		const ___temp___tickets_types___collection: type___api___response = await this._API.tickets_types___read___multi(this.state.event___id);
		this.setState({
			event___tickets_collection: ___temp___tickets_types___collection.data
		});
	}

//#endregion

//#region 																							H A N D L E R S

	handle___page_refresh = async (___e: CustomEvent<RefresherEventDetail>) => {
		await this.read_props___event();
		___e.detail.complete();
	}

	handle___preview___ticket_created = (___ticket_type: type___ticket___type, ___id: string | null = this.state.event___props['event_id']) => {
		this.setState({
			ticket_created_preview___visible: true,
			ticket_created_preview___type: ___ticket_type,
			ticket_created_preview___id: ___id,
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	ionViewDidEnter() : void {
		const ___event___id: string = this._ROUTER.get_id(this.props);
		const ___event___id___selected: string | null = localStorage.getItem(lib_names.localStorage.user___event_selected);
		this.setState({
			event___id: ___event___id,
			event___id___selected: ___event___id___selected,
		}, async () => {
			await this.read_props___event();
		});
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{

		return <>

			<IonActionSheet isOpen={ this.state.actionSheet___set_event_as_main___visible } {...this.element___IonActionSheet___props} onDidDismiss={() => { this.setState({ actionSheet___set_event_as_main___visible: false }); }}></IonActionSheet>
	
			<MdTicketViewModal isOpen={ this.state.ticket_created_preview___visible && this.state.ticket_created_preview___id } ticket_type={ this.state.ticket_created_preview___type } props_id={ this.state.ticket_created_preview___id } event___onDidDismiss={() => { this.setState({ ticket_created_preview___visible: false, ticket_created_preview___id: null }); }}/>
			<MdTicketEditModal isOpen={ this.state.event___props && this.state.ticket___is_creating && this.state.ticket___is_creating___type } ticket_type={ this.state.ticket___is_creating___type } ticket_id={ null } event___onDidDismiss={(___e: string) => { this.handle___preview___ticket_created('standard', ___e); }}/>

			<IonPage>

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/events/list"></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent>

					<IonRefresher slot="fixed" onIonRefresh={ this.handle___page_refresh }>
						<ComSpinnerRefreshComponent/>
					</IonRefresher>

					{(this.state.event___props !== null) ? <>
						<div className="sb-page---container sb-event-view---container">

							{(this.state.event___id === this.state.event___id___selected) ? <>
								<ComEventSelectedComponent/>
							</> : <>
								<IonCard>
									<IonList>
										<IonItem button={ true } className="is-ion-item-button" onClick={() => { this.setState({ actionSheet___set_event_as_main___visible: true }); }}>
											<i className="fas fa-calendar-check"></i>
											<IonLabel><b>Seleziona come evento attuale</b></IonLabel>
										</IonItem>
									</IonList>
								</IonCard>
							</>}

							<br />

							<ComEventTimeScheduleComponent event___props={ this.state.event___props } />
						
							<br />

							<div className="sb-event-view---image">
								{(this.state.event___props['event_poster'] && this.state.event___props['event_poster'].length > 0) ? <>
									<img src={ this.state.event___props['event_poster'] } />
								</> : <>
									<img src={ '/assets/markers/event/' + this.state.event___props['event_category']['event_category_icon'] + '.svg' } />
								</>}
							</div>

							<br />

							<h3>{ this.state.event___props['event_name'] }</h3>

							<ComEventTextComponent text={ this.state.event___props['event_description'] }/>

							<br />
							<br />

{/**
							{(this.state.event___props['event_attributes_musicgenres'] && this.state.event___props['event_attributes_musicgenres'].length > 0) ? <>
								<IonListHeader>
									<IonLabel>Generi Musicali</IonLabel>
								</IonListHeader>
								<br />
								<section>
									{this.state.event___props['event_attributes_musicgenres'].map((___eamg: any, ___eamg___idx: number) => {
										return <>
											<IonCard key={ ___eamg___idx }>
												<ComEventAttributeMusicGenreComponent musicgenre___props={ ___eamg }/>
											</IonCard>
										</>;
									})}
								</section>
							</> : <></>}
 */}
 
							<br />
							<br />
							<br />

						</div>
					</> : <>
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						<ComSpinnerComponent size="small"/>
					</>}

				</IonContent>

			</IonPage>

		</>;

	}

//#endregion

}

//	E X P O R T 

export default withIonLifeCycle(SbEventsViewPage);