import React from 'react';
import { IonContent, IonModal, withIonLifeCycle } from '@ionic/react';

//	S T Y L E

import './md-cashdesk-success.scss';

//	S T A T E   -   P R O P S

import { props___MdCashDeskSuccessModal } from './md-cashdesk-success.state';

//	C L A S S

class MdCashDeskSuccessModal extends React.Component<props___MdCashDeskSuccessModal, {}>
{

//#region 																							C O N F I G

	private readonly MdCashDeskSuccessModal___modal_config: any = {
		backdropDismiss: false,
		handle: false,
		keyboardClose: false,
		showBackdrop: true,
	};

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonModal isOpen={ this.props.isOpen } { ...this.MdCashDeskSuccessModal___modal_config }>
				<IonContent>
					<section className="md-cashdesk-success---container">
						<section color="success" onClick={() => { this.props.event___onDidDismiss(); }}>
							<br />
							<section>
								<br />
								<i className="fas fa-check-square"></i>
								<br />
								<br />
								<h1>Ticket Validato con successo</h1>
								<br />
							</section>
							<br />
							<br />
							<br />
							<h6>clicca ovunque per chiudere</h6>
							<br />	
						</section>
					</section>
				</IonContent>
			</IonModal>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(MdCashDeskSuccessModal);