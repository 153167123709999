import React from 'react'

//	S T Y L E

import './com-spinner-more.scss'

//	C L A S S

export default class ComSpinnerMoreComponent extends React.Component<{}, {}>
{
//#region 																							R E N D E R

	render() : React.ReactNode
	{

		return <>
			<div className="com-spinner-more---component">
				<i className="fa-solid fa-circle-notch"></i>
			</div>
		</>;

	}

//#endregion

}