import { interface___modal } from "../../interfaces/interface.modals";
import { type___ticket___object, type___ticket___type } from "../../types/types.types";

//	P R O P S

export interface props___MdTicketViewModal extends interface___modal {

	ticket_object: type___ticket___object | null,

}

//	S T A T E

export interface state___MdTicketViewModal {

	user___props: any | null,
	user___props___is_loading: boolean,

	ticket___props: any | null,
	ticket___props___is_loading: boolean,

	ticket___type: type___ticket___type | null,
	ticket___type_props: any | null,
	ticket___type_props___is_loading: boolean,

	event___props: any | null,
	event___props___is_loading: boolean,






}

//	S T A T E   D E F A U L T

export const state___MdTicketViewModal___default: state___MdTicketViewModal = {

	user___props: null,
	user___props___is_loading: true,

	ticket___props:  null,
	ticket___props___is_loading: true,

	ticket___type: null,
	ticket___type_props: null,
	ticket___type_props___is_loading: true,

	event___props: null,
	event___props___is_loading: true,






}