//	P R O P S

export interface props___SbLoginPage {

	event___setMeProps: any,

}

//	S T A T E

export interface state___SbLoginPage {

	login___is_loading: boolean,

	field___username: string, 
	field___password: string,
	field___eula_accepted: boolean,

	modal___password_reset___visible: boolean,
	modal___login_eula___visible: boolean,

	toast___login_error___text: string,
	toast___login_error___visible: boolean,

}

//	S T A T E   D E F A U L T

export const state___SbLoginPage___default: state___SbLoginPage = {

	login___is_loading: false,

	field___username: '', 
	field___password: '',
	field___eula_accepted: false,

	modal___password_reset___visible: false,
	modal___login_eula___visible: false,

	toast___login_error___text: '',
	toast___login_error___visible: false,

}