import { interface___page } from "../../interfaces/interface.page";
import { type___ticket___object, type___ticket___type } from "../../types/types.types";

//	P R O P S

export interface props___SbDiscoverFeedPage extends interface___page {
	
}

//	S T A T E

export interface state___SbDiscoverFeedPage {

	event___props: any | null,
	event___props___loading: boolean,

	ticket___is_creating: boolean,
	ticket___is_creating___type: type___ticket___type | null,

	ticket_created_preview___visible: boolean,
	ticket_created_preview___object: type___ticket___object | null,

}

//	S T A T E   D E F A U L T

export const state___SbDiscoverFeedPage___default: state___SbDiscoverFeedPage = {

	event___props: null,
	event___props___loading: true,

	ticket___is_creating: false,
	ticket___is_creating___type: null,

	ticket_created_preview___visible: false,
	ticket_created_preview___object: null,

}