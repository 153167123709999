import React from 'react';
import { IonBackButton, IonBadge, IonButtons, IonContent, IonHeader, IonItem, IonLabel, IonList, IonListHeader, IonPage, IonRefresher, IonToolbar, RefresherEventDetail, withIonLifeCycle } from '@ionic/react';

//	S T Y L E

import './sb-payments-users.scss';

//	F U N C S

import { funcs_numbers } from '../../../funcs/funcs.numbers';

//	S T A T E   -   P R O P S

import { props___SbPaymentsUsersPage, state___SbPaymentsUsersPage, state___SbPaymentsUsersPage___default } from './sb-payments-users.state';

//	S E R V I C E S

import { service_RestApiService } from '../../../services/service-api';

//	C O M P O N E N T S

import ComSpinnerComponent from '../../../components/com-spinner/com-spinner';
import ComSpinnerRefreshComponent from '../../../components/com-spinner-refresh/com-spinner-refresh';

//	C L A S S

class SbPaymentsUsersPage extends React.Component<props___SbPaymentsUsersPage, state___SbPaymentsUsersPage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();

	private readonly funcs____numbers: funcs_numbers = new funcs_numbers();

//	private ___htmlRef___chart___analytics_event = createRef<HTMLCanvasElement>();
//	private ___htmlRef___chart___analytics_event___instance?: ChartJS<'bar'>;

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbPaymentsUsersPage,
	)
	{
		super(props);
		this.state = state___SbPaymentsUsersPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	rear_props___payments_users = async () => {
		const ___temp___payments_users: any = await this._API.payments_users___read___multi('standard');
		if (___temp___payments_users.data === null) { return; }
		this.setState({
			payments_users___props: ___temp___payments_users.data,
			payments_users___props___is_loading: false,
		});
	}
	
//#endregion

//#region 																							H A N D L E R S

	handle___page_refresh = async (___e: CustomEvent<RefresherEventDetail>) => {
		await this.rear_props___payments_users();
		___e.detail.complete();
	}

	handle___users___sort = (___event: any) => {
		console.log('___event', ___event)
	//	this.setState({
	//		event_users___props: [],
	//		event_users___props___order: ___event.detail.value,
	//		event_users___props___is_loading: true,
	//	}, async () => {
	//		await this.rear_props___chart___user_tickets();
	//	});
	}

//#endregion

//#region 																							L I F E C Y C L E

	async ionViewDidEnter() : Promise<void> {
		await this.rear_props___payments_users();
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{

		return <>

			<IonPage>

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/dashboard"></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent>

					<IonRefresher slot="fixed" onIonRefresh={ this.handle___page_refresh }>
						<ComSpinnerRefreshComponent/>
					</IonRefresher>

					<IonListHeader>
						<IonLabel>Lista Rientri non pagati</IonLabel>
					</IonListHeader>

					<br />

					{(this.state.payments_users___props___is_loading === false) ? <>
						<IonList className="sb-payments-users---pr-list">
							{this.state.payments_users___props.map((___pu: any, ___pu___idx: number) => {
								return <>
									<IonItem key={ ___pu___idx } className="is-ion-item-button" button={ true } routerLink={ '/payments/userview/' + ___pu['user_id'] } style={{opacity:((___pu['user_tickets_remaining_to_pay'] > 0) ? 1 : 0.25)}}>
										<IonLabel>
											<b>{ ___pu['user_name'] + ' ' + ___pu['user_surname'] }</b>
											{(___pu['user_name_displayed'] && ___pu['user_name_displayed'].length > 0) ? <>
												<span style={{opacity:0.5,fontWeight:'bold'}}> - { ___pu['user_name_displayed'] }</span>
											</> : <></>}
										</IonLabel>
										{(___pu['user_tickets_remaining_to_pay'] > 0) ? <>
											<IonBadge style={{textAlign:'right'}} slot="end" color="primary">
												<span>{ ___pu['user_tickets_remaining_to_pay'] }</span>
												<span> €</span>
											</IonBadge>
										</> : <>
											<IonBadge style={{textAlign:'right'}} slot="end" color="light">
												<span>Nessun Rientro</span>
											</IonBadge>
										</>}
									</IonItem>
								</>;
							})}
						</IonList>
					</> : <>
						<br />
						<br />
						<ComSpinnerComponent size="small"/>
						<br />
						<br />
					</>}

					<br />

					{/**

						<IonListHeader>
							<IonLabel>Panoramica Orari Entrate</IonLabel>
						</IonListHeader>
						
						<br />

						<IonListHeader>
							<IonLabel>Panoramica Orari Entrate</IonLabel>
						</IonListHeader>
						
						<br />

						{(this.state.event___props !== null) ? <>
							<div className="sb-page---container sb-payments-users---container">
								{(this.state.event_analytics___chart___is_loading === false) ? <>
									<canvas ref={ this.___htmlRef___chart___analytics_event } />
								</> : <></>}
							</div>
						</> : <>
							<br />
							<br />
							<br />
							<br />
							<br />
							<br />
							<ComSpinnerComponent size="small"/>
						</>}

					*/}

					<br />
					<br />
					<br />

				</IonContent>

			</IonPage>

		</>;

	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbPaymentsUsersPage);