import React from 'react'
import { IonActionSheet } from '@ionic/react';
import { toJpeg } from 'html-to-image';

//	L I B S

import { lib_names_shared } from '../../libs/lib.names.shared';

//	I N T E R F A C E S

import { interface___IonActionSheet } from '../../interfaces/interface.actionsheet';

//	S T A T E S   -   P R O P S

import { props___ComButtonTicketShareComponent, state___ComButtonTicketShareComponent, state___ComButtonTicketShareComponent___default } from './com-button-ticket-share.state';

//	C L A S S

export default class ComButtonTicketShareComponent extends React.Component<props___ComButtonTicketShareComponent, state___ComButtonTicketShareComponent>
{

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComButtonTicketShareComponent,
	)
	{
		super(props);
		this.state = state___ComButtonTicketShareComponent___default;
	}

//#endregion

//#region 																							I N T E R F A C E S

	private readonly element___IonActionSheet___share_options = () : interface___IonActionSheet => {
		const ___button___share: any = [{
			text: 'Condividi',
			handler: async () => { 
				await this.handle___share_link();
			}
		}];
		const ___button___save_image: any = [{
			text: 'Salva Immagine',
			handler: async () => { 
				await this.handle___share_image();
			}
		}];
		return { buttons: [
			...___button___share,
			...___button___save_image,
			{
				text: 'Annulla',
				role: 'cancel',
			}
		]}
	};

//#endregion

//#region 																							C O N S T R U C T O R

	async handle___share_image() : Promise<void>
	{
		if (!this.props.ticket_dom_reference.current || this.props.ticket_dom_reference.current === null) { return; }
		try
		{
			const ___ticket_screen___dataUrl = await toJpeg(this.props.ticket_dom_reference.current, { quality: 0.95 });
			const ___ticket_screen___link = document.createElement('a');
			___ticket_screen___link.href = ___ticket_screen___dataUrl;
			___ticket_screen___link.download = this.props.ticket_code;
			___ticket_screen___link.click();
		}
		catch (error)
		{
			alert('Impossibile salvare l\'immagine, se l\'errore persiste contatta l\'amministratore o l\'assistenza');
		}
	}

	async handle___share_link() : Promise<void>
	{
		const ___url_to_share: string = this.state.navigator___url;
		if (navigator.share)
		{
			try
			{
				await navigator.share({
					title: this.state.navigator___title,
					text: this.state.navigator___text,
					url: ___url_to_share,
				});
			}
			catch (error)
			{
				await navigator.clipboard.writeText(___url_to_share);
				alert('Link all\'invito copiato, incollalo dove vuoi');
			}
		}
		else
		{
			await navigator.clipboard.writeText(___url_to_share);
			alert('Link all\'invito copiato, incollalo dove vuoi');
		}
	}

//#endregion

//#region 																							L I F E C Y C L E

	componentDidMount() : void
	{

		switch (this.props.ticket_type) {
			case 'invite':
				this.setState({
					navigator___title: (this.props.ticket_event_props !== null) ? 'Invito per l\'evento "' + this.props.ticket_event_props['event_name'] + '"' : '',
					navigator___text: (this.props.ticket_event_props !== null) ? 'Ciao, sei nella mia lista inviti per l\'evento "' + this.props.ticket_event_props['event_name'] + '", scarica il QrCode per dal questo link e ricordati di tenerlo pronto all\'ingresso' : '',
					navigator___url: lib_names_shared.endpoints.qrs_nopr_invite + this.props.ticket_code,
				});
			break
			case 'standard':
				this.setState({
					navigator___title: (this.props.ticket_event_props !== null) ? 'Ticket per l\'evento "' + this.props.ticket_event_props['event_name'] + '"' : '',
					navigator___text: (this.props.ticket_event_props !== null) ? 'Ciao, ecco il tuo ticket per l\'evento "' + this.props.ticket_event_props['event_name'] + '", scarica il QrCode per dal questo link e ricordati di tenerlo pronto all\'ingresso' : '',
					navigator___url: lib_names_shared.endpoints.qrs_nopr_standard + this.props.ticket_code,
				});
			break
		}

	}

//#endregion

//#region 																							R E N D E R

	render(): React.ReactNode
	{

		return <>

			<IonActionSheet isOpen={ this.state.actionSheet___ticket_share___visibile } { ...(this.element___IonActionSheet___share_options()) } onDidDismiss={() => { this.setState({ actionSheet___ticket_share___visibile: false }); }} />

			<button className="is-element is-button is-color---folly is-full-width" onClick={() => { this.setState({ actionSheet___ticket_share___visibile: true }); }}>
				<i className="fa-solid fa-share"></i>
				<h4>Condividi</h4>
			</button>

		</>;

	}

//#endregion

}