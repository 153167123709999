//	P R O P S

//export interface props___SbEventsListPage {
	
//}

//	S T A T E

export interface state___SbEventsListPage {

	me___props: any | null,
	me___props___is_loading: boolean,

	user___collection___admins: any[],
	user___collection___prs: any[],
	user___collection___cashdesks: any[],
	user___collection___is_loading: boolean,

	actionSheet___user_options___target: string,
	actionSheet___user_options: boolean,

}

//	S T A T E   D E F A U L T

export const state___SbEventsListPage___default: state___SbEventsListPage = {

	me___props: null,
	me___props___is_loading: true,

	user___collection___admins: [],
	user___collection___prs: [],
	user___collection___cashdesks: [],
	user___collection___is_loading: true,











	actionSheet___user_options___target: '',
	actionSheet___user_options: false,

}