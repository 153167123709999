//	S T A T E

export interface state___MdTicketEditModal {

	alert___logout_check___visibility: boolean,

}

//	S T A T E   D E F A U L T

export const state___MdTicketEditModal___default: state___MdTicketEditModal = {

	alert___logout_check___visibility: false,

}