import React from 'react'

//	S T Y L E

import './com-ticket-valid.scss'

//	S T A T E S   -   P R O P S

import { props___ComTicketValidComponent } from './com-ticket-valid.state';

//	C L A S S

export default class ComTicketValidComponent extends React.Component<props___ComTicketValidComponent, {}>
{

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComTicketValidComponent,
	)
	{
		super(props);
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{

		return <>
			{(() => {
				switch (this.props.ticket_valid) {
					case true: return <>
						<section className="com-ticket-valid---container com-ticket-valid---is-valid">
							<h4>
								{(() => {
									switch (this.props.ticket_type) {
										case 'invite': return <>INVITO </>; break;
										case 'ticket': return <>TICKET </>; break;
									}
								})()}
								<>VALIDO</>
							</h4>
						</section>
					</>; break;
					case false: return <>
						<section className="com-ticket-valid---container com-ticket-valid---is-not-valid">
							<h4>
								{(() => {
									switch (this.props.ticket_type) {
										case 'invite': return <>INVITO </>; break;
										case 'ticket': return <>TICKET </>; break;
									}
								})()}
								<>NON VALIDO</>
							</h4>
						</section>
					</>; break;
				}
			})()}
		</>;

	}

//#endregion

}