import { type___ticket___object, type___ticket___type } from "../types/types.types";
import { funcs_encrypt } from "./funcs.encrypt";

//	E X P O R T   F U N C S

export class funcs_tickets
{

//#region 																							D E C L A R A T I O N S

	private readonly funcs___encrypt: funcs_encrypt = new funcs_encrypt();

//#endregion

//#region 																							T I C K E T   E N C R Y P T

	ticket___encrypt(___type: type___ticket___type, ___id: string | null, ___event: string | null, ___sender: string | null) : { clear: type___ticket___object, encrypted: string }
	{

		const ___ticket___clear: type___ticket___object = { ticket_type: ___type, ticket_id: ___id ?? null, ticket_event: ___event ?? null, ticket_pr: ___sender ?? null };
		const ___ticket___json: string = JSON.stringify(___ticket___clear);
		const ___ticket___json___encrypted: string = this.funcs___encrypt.base32___encrypt(___ticket___json);
		
		return {
			clear: ___ticket___clear,
			encrypted: ___ticket___json___encrypted
		}

	}

//#endregion

//#region 																							T I C K E T   E N C R Y P T

	ticket___encrypt___from_object(___to_encrypt: type___ticket___object) : string
	{
		const ___ticket___json: string = JSON.stringify(___to_encrypt);
		const ___ticket___json___encrypted: string = this.funcs___encrypt.base32___encrypt(___ticket___json);
		return ___ticket___json___encrypted;
	}

//#endregion

//#region 																							T I C K E T  D E C R Y P T

	ticket___decrypt___to_object(___to_decrypt: string) : type___ticket___object
	{
		const ___ticket___json___decrypted: string = this.funcs___encrypt.base32___decrypt(___to_decrypt);
		const ___ticket___json: type___ticket___object = JSON.parse(___ticket___json___decrypted) as type___ticket___object;
		return ___ticket___json;
	}

//#endregion

}