import React from 'react';
import { IonContent, IonModal } from '@ionic/react';

//	S T Y L E

import './md-ticket-type-select.scss';

//	S T A T E   -   P R O P S

import { props___MdTicketEditTypeModal, state___MdTicketEditTypeModal, state___MdTicketEditTypeModal___default } from './md-ticket-type-select.state';

//	T Y P E S

import { type___api___response } from '../../types/types.api-response';

//	S E R V I C E S

import { service_RestApiService } from '../../services/service-api';

//	C O M P O N E N T S

import ComListEmptyComponent from '../../components/com-list-empty/com-list-empty';
import ComSpinnerButtonComponent from '../../components/com-spinner-button/com-spinner-button';
import ComTicketTypeComponent from '../../components/com-ticket-type/com-ticket-type';

//	C L A S S

export default class MdTicketTypeSelectModal extends React.Component<props___MdTicketEditTypeModal, state___MdTicketEditTypeModal>
{

//#region 																							D E C L A R A T I O N S
		
	private readonly _API: service_RestApiService = new service_RestApiService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___MdTicketEditTypeModal,
	)
	{
		super(props);
		this.state = state___MdTicketEditTypeModal___default;
	}

//#endregion

//#region 																							C O N S T R U C T O R

	read_props___ticket_types = async () => {
		const ___temp___ticket_props: type___api___response = await this._API.tickets_types___read___multi(this.props.event_id);
		this.setState({
			ticket___type_collection: (___temp___ticket_props.response === 'success') ? ___temp___ticket_props['data'] : [],
			ticket___type_collection___is_loading: false,
		});
	}

//#endregion

//#region 																							H A N D L E R S

	handle___ticket_type___select = async (___ticket: any) => {
		switch (___ticket['ticket_type']) {
			case 'online':
				console.log('0adsjnasd')
				break;
			case 'standard': 
				this.props.event___onDidDismiss(___ticket);
				break;
		}
	}

//#endregion

//#region 																							L I F E C Y C L E

	async componentDidMount() : Promise<void>
	{
		await this.read_props___ticket_types();
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{

		return <>

			<IonModal isOpen={ this.props.isOpen } backdropDismiss={ false } >
				<IonContent>
					<div className="md-ticket-edit-type---container">

						<section className="md-ticket-edit-type---title">
							<h2>Seleziona Tipo di Ticket</h2>
						</section>
						
						{(this.state.ticket___type_collection___is_loading === true) ? <>
							<ComSpinnerButtonComponent/>
						</> : <>
							{(this.state.ticket___type_collection.length > 0) ? <>
								{this.state.ticket___type_collection.map((___tt: any, ___tt_idx: number) => {
									return <>
										{(!(this.props.can___haveOnlineTickets === false && ___tt['ticket_type'] === 'online')) ? <>
											<section key={ ___tt_idx} onClick={() => { this.handle___ticket_type___select(___tt); }}>
												<ComTicketTypeComponent ticket_type___props={ ___tt }/>
											</section>
										</> : <></>}
									</>;
								})}
							</> : <>
								<ComListEmptyComponent/>
							</>}
						</>}

					</div>
			</IonContent>
		</IonModal>

		</>;

	}

//#endregion

}