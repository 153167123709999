import React from 'react';
import { IonAlert, IonBadge, IonButton, IonButtons, IonCard, IonContent, IonFooter, IonHeader, IonItem, IonLabel, IonList, IonListHeader, IonModal, IonNote, IonToolbar, withIonLifeCycle } from '@ionic/react';

//	S T Y L E

import './md-ticket-edit.scss';

//	I N T E R F A C E S

import { interface___IonAlert } from '../../interfaces/interface.alert';

//	L I B S

import { lib_names } from '../../libs/lib.names';

//	S T A T E   -   P R O P S

import { props___MdTicketEditModal, state___MdTicketEditModal, state___MdTicketEditModal___default } from './md-ticket-edit.state';

//	T Y P E S

import { type___api___response } from '../../types/types.api-response';
import { type___ticket___object } from '../../types/types.types';

//	S E R V I C E S

import { service_RestApiService } from '../../services/service-api';

//	C O M P O N E N T S

import ComSpinnerButtonComponent from '../../components/com-spinner-button/com-spinner-button';
import ComSpinnerComponent from '../../components/com-spinner/com-spinner';
import ComEventComponent from '../../components/com-event/com-event';
import ComTicketTypeComponent from '../../components/com-ticket-type/com-ticket-type';

//	M O D A L S

import MdTicketTypeSelectModal from '../md-ticket-type-select/md-ticket-type-select';

//	C L A S S

class MdTicketEditModal extends React.Component<props___MdTicketEditModal, state___MdTicketEditModal>
{

//#region 																							C O N F I G

	private readonly MdTicketEditModal___modal_config: any = {
		breakpoints: [0, 1],
		backdropDismiss: true,
		handle: true,
		initialBreakpoint: 1,
		keyboardClose: true,
		showBackdrop: true,
		onDidPresent: () => { this.event___onDidPresent() },
		onDidDismiss: () => { this.props.event___onDidDismiss(); }
	};

//#endregion

//#region 																							D E C L A R A T I O N S
		
	private readonly _API: service_RestApiService = new service_RestApiService();

//#endregion

//#region 																							I N T E R F A C E S

	private readonly element___IonAlert___cancel_ticket = () : interface___IonAlert => {
		let ___header: string = '';
		let ___message: string = '';
		switch (this.props.ticket_type) {
			case 'invite':
				___header = 'Attenzione';
				___message = 'Vuoi eliminare le modifiche a questo Invito?';
			break;
			case 'standard':
				___header = 'Attenzione';
				___message = 'Vuoi eliminare le modifiche a questo Ticket?';
			break;
		}
		return {
			header: ___header,
			message: ___message,
			buttons: [
				{
					text: 'Si',
					handler: () => {
						this.setState({
							...state___MdTicketEditModal___default
						}, () => {
							this.props.event___onDidDismiss(null);
						});
					}
				},
				{
					text: 'Annulla',
					role: 'cancel',
					handler: () => { this.setState({ alert___askForClose: false });}
				}
			]
		};
	};

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___MdTicketEditModal,
	)
	{
		super(props);
		this.state = state___MdTicketEditModal___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	read_props___ticket = async () => {
		if (this.props.ticket_id && this.props.ticket_id !== null)
		{
			const ___temp___ticket_props: type___api___response = await this._API.tickets___read___single(this.props.ticket_id);
			this.setState({
				ticket___type_reference: (___temp___ticket_props.response === 'success' && this.props.ticket_type !== 'invite') ? ___temp___ticket_props['data']['ticket_type'] : null,
				ticket___person_name: (___temp___ticket_props.response === 'success') ? ___temp___ticket_props['data']['ticket_person_name'] : '',
				ticket___event_id: (___temp___ticket_props.response === 'success') ? ___temp___ticket_props['data']['ticket_event'] : '',
				ticket___notes: (___temp___ticket_props.response === 'success') ? ___temp___ticket_props['data']['ticket_notes'] : '',
				ticket___props___is_loading: false,
			}, async () => {
				await this.read_props___event();
			});
		}
		else
		{
			this.setState({
				ticket___props___is_loading: false,
			}, async () => {
				await this.read_props___event();
			});
		}
	}

	read_props___event = async () => {
		const ___tosearch___event_id: string | null = localStorage.getItem(lib_names.localStorage.user___event_selected);
		if (___tosearch___event_id)
		{
			const ___temp___event_props: type___api___response = await this._API.events___read___single(___tosearch___event_id);
			this.setState({
				ticket___event_id: ___tosearch___event_id,
				ticket___event_props: ___temp___event_props.data,
				ticket___event_props___is_loading: false
			});
		}
		else
		{
			this.props.event___onDidDismiss();
		}
	}

//#endregion

//#region 																							H A N D L E R S

	handle___MdTicketEditModal___askClose = () => {
		this.setState({
			alert___askForClose: true
		});
	}

	handle___select___ticket_type = (___ticket_type_props: any) => {
		this.setState({
			ticket___type_reference: ___ticket_type_props,
			ticket___type_reference___is_selecting: false
		});
	}

//#endregion

//#region 																							S U B M I T

	submit___ticket = () => {
		this.setState({
			ticket___props___is_loading: true
		}, async () => {
			const ___ticket_is_submitted___type_reference: string = (this.props.ticket_type === 'invite') ? null : this.state.ticket___type_reference['ticket_type_id'];
			const ___ticket_is_submitted: type___api___response = await this._API.tickets___submit(this.props.ticket_id, this.state.ticket___event_id!, this.props.ticket_type, ___ticket_is_submitted___type_reference, this.state.ticket___person_name, this.state.ticket___notes);
			if (___ticket_is_submitted['response'] === 'success')
			{
				const ___ticket___submitted: type___ticket___object = {
					ticket_id: ___ticket_is_submitted['data'],
					ticket_type: this.props.ticket_type,
					ticket_pr: this.state.me___props['user_id'],
					ticket_event: this.state.ticket___event_props['event_id'],
				};
				this.props.event___onDidDismiss({
					...___ticket___submitted
				});
			}
			else
			{
				
				
				
				
				
				
				console.log('probkena oorijsjhndjjsjndknaojjln');










			}
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	event___onDidPresent() : void
	{
		this.setState({
			ticket___type_reference___is_selecting: (this.props.ticket_type === 'invite' || this.props.ticket_id) ? false : true, 
		}, async () => {
			const ___me_props: type___api___response = await this._API.users___read___single('me');
			if (!___me_props || ___me_props['response'] !== 'success') { console.log('problems in user'); }
			this.setState({
				me___props: ___me_props.data,
				me___props___is_loading: true,
			}, async () => {
				await this.read_props___ticket();
			});
		});
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{

		return <>

			<IonAlert isOpen={ this.state.alert___askForClose } { ...(this.element___IonAlert___cancel_ticket()) }></IonAlert>

			<IonModal isOpen={ this.props.isOpen } { ...this.MdTicketEditModal___modal_config }>

				<MdTicketTypeSelectModal isOpen={ this.state.ticket___type_reference___is_selecting && this.state.ticket___event_id !== null} event_id={ this.state.ticket___event_id ?? '' } event___onDidDismiss={ this.handle___select___ticket_type } can___haveOnlineTickets={ false }/>

				<IonHeader className="ion-no-border" collapse="fade">
					<IonToolbar>
						<IonButtons slot="end">
							<IonButton onClick={ this.handle___MdTicketEditModal___askClose }>annulla</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				
				<IonContent>

					<IonListHeader>
						{(this.props.ticket_id === null) ? <>
							<IonLabel>Nuovo Ticket</IonLabel>
						</> : <>
							<IonLabel>Modifica Ticket</IonLabel>
						</>}
					</IonListHeader>

					<section className="md-ticket-edit---container">
						
						{(this.state.ticket___props___is_loading === true) ? <>
							<ComSpinnerButtonComponent/>
						</> : <>

						<br />

							<p className="input---nb-label">Evento selezionato</p>
							{(this.state.ticket___event_props___is_loading === true) ? <>
								<ComSpinnerComponent size="small"/>
							</> : <>
								{(this.state.ticket___event_props !== null) ? <>
									<IonCard>
										<ComEventComponent event___props={ this.state.ticket___event_props }/>
									</IonCard>
								</> : <>
									<IonCard>
										<IonList>
											<IonItem>
												<IonLabel>Nessun evento selezionato</IonLabel>
											</IonItem>
										</IonList>
									</IonCard>
								</>}
							</>}

							<br />

							<p className="input---nb-label is-required">Tipologia del Ticket</p>
							{(this.props.ticket_type === 'invite') ? <>
								<IonCard>
									<IonList>
										<IonItem className="is-ion-item-button">
											<i className="fas fa-calendar-week"></i>
											<IonLabel>Invito</IonLabel>
											<IonBadge>Nome In Lista</IonBadge>
										</IonItem>
									</IonList>
								</IonCard>
							</> : <>
								{(this.state.ticket___type_reference === null) ? <>
									<IonCard>
										<IonList>
											<IonItem className="is-ion-item-button" onClick={() => { this.setState({ ticket___type_reference___is_selecting: true }); }}>
													<i className="fas fa-ticket"></i>
													<IonLabel>Tipologia Ticket</IonLabel>
													<IonNote>
														<i className="fas fa-ellipsis"></i>
													</IonNote>
											</IonItem>
										</IonList>
									</IonCard>
								</> : <>
									<section onClick={() => { this.setState({ ticket___type_reference___is_selecting: true }); }}>
										<ComTicketTypeComponent ticket_type___props={ this.state.ticket___type_reference } />
									</section>
								</>}
							</>}

							<br />

							<label htmlFor="field---ticket-person-name">
								{(this.props.ticket_type === 'invite') ? <>
									<p className="input---nb-label is-required">Intestazione dell'invito</p>
								</> : <>
									<p className="input---nb-label is-required">Intestazione del Ticket</p>
								</>}
								<input type="text" id="field---ticket-person-name" className="input---nb-outlined" placeholder="Nome Cognome" value={ this.state.ticket___person_name } onInput={(___e: any) => { this.setState({ ticket___person_name: ___e.target.value}); }}/>
							</label>

							<br />

							<label htmlFor="field---event-description">
								{(this.props.ticket_type === 'invite') ? <>
									<p className="input---nb-label">Note sull'invito</p>
								</> : <>
									<p className="input---nb-label">Note sul Ticket</p>
								</>}
								<textarea id="field---event-description" className="input---nb-outlined" placeholder="Note visibili solo allo staff..." style={{height:'150pt'}} value={ this.state.ticket___notes } onInput={(___e: any) => { this.setState({ ticket___notes: ___e.target.value}); }}></textarea>
							</label>

						</>}

					</section>

				</IonContent>
				
				<IonFooter>
					<section className="container---footer-section">
						<button className="is-element is-button is-color---folly is-full-width" onClick={ this.submit___ticket }>
							<i className="fa-solid fa-check"></i>
							<h4>Salva Ticket</h4>
						</button>
					</section>
				</IonFooter>

			</IonModal>

		</>;

	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(MdTicketEditModal);