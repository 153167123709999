import React from 'react';
import { IonContent, IonFooter, IonLabel, IonListHeader, IonModal, IonToast } from '@ionic/react';

//	S T Y L E

import './md-sign-password-change.scss';

//	S T A T E S   -   P R O P S

import { props___MdSignPasswordChangeModal, state___MdSignPasswordChangeModal, state___MdSignPasswordChangeModal___default } from './md-sign-password-change.state';

//	T Y P E S

import { type___api___response } from '../../types/types.api-response';

//	S E R V I C E S

import { service_RestApiService } from '../../services/service-api';

//	C L A S S

export default class MdSignPasswordChangeModal extends React.Component<props___MdSignPasswordChangeModal, state___MdSignPasswordChangeModal>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();

//#endregion

//#region 																							C O N F I G

	private readonly MdSignPasswordChangeModal___modal_config: any = {
		backdropDismiss: false,
		handle: false,
		initialBreakpoint: 1,
		keyboardClose: true,
		showBackdrop: true,
		onDidPresent: () => { this.event___onDidPresent(); },
		onDidDismiss: () => { this.event___onDidDismiss(); }
	};

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___MdSignPasswordChangeModal,
	)
	{
		super(props);
		this.state = state___MdSignPasswordChangeModal___default;
	}

//#endregion

//#region 																							S U B M I T

	submit___password_change = () => {
		if (this.state.field___password === this.state.field___password___repeat)
		{
			this.setState({
				password_change___is_loading: true,
			}, async () => {
				const ___password___is_resetted: type___api___response = await this._API.auth___perform___password_change(null, this.state.field___password);
				if (___password___is_resetted && ___password___is_resetted.response === 'success')
				{
					this.setState({
						toast___password_change_success___visible: true,
						password_change___is_loading: false,
					}, () => {


						console.log('dismiss')


						this.props.event___onDidDismiss();
					});
				}
				else
				{
					this.setState({
						toast___password_change_error___visible: true,
						password_change___is_loading: false,
					});
				}
			});
		}
		else
		{
			this.setState({
				toast___password_change_unmatch___visible: true,
			});
		}
	}

//#endregion

//#region 																							L I F E C Y C L E

	event___onDidPresent() : void
	{
		this.setState({
			password_change___is_loading: false,
		});
	}

	event___onDidDismiss() : void
	{
		this.setState({
			...state___MdSignPasswordChangeModal___default
		}, () => {
			this.props.event___onDidDismiss();
		});
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>

			<IonToast isOpen={ this.state.toast___password_change_success___visible } message="La tua password è stata cambiata con successo" duration={ 3000 } color="success" position="top" onDidDismiss={() => { this.setState({ toast___password_change_success___visible: false }); }}/>
			<IonToast isOpen={ this.state.toast___password_change_unmatch___visible } message="Le password non combaciano..." duration={ 3000 } color="danger" position="top" onDidDismiss={() => { this.setState({ toast___password_change_unmatch___visible: false }); }}/>
			<IonToast isOpen={ this.state.toast___password_change_error___visible } message="Errore nel cambiamento della password" duration={ 3000 } color="danger" position="top" onDidDismiss={() => { this.setState({ toast___password_change_error___visible: false }); }}/>

			<IonModal isOpen={ this.props.isOpen } { ...this.MdSignPasswordChangeModal___modal_config }>

				<IonContent>
					<section className="md-sign-password-change---container">
						<br />
						<br />
						<img src="/assets/logo-horizontal.svg"></img>
						<br />
						<br />
						<IonListHeader style={{textAlign:'center'}}>
							<IonLabel>Reset della password</IonLabel>
						</IonListHeader>
						<br />
						<p><b>Al momento hai una password temporanea</b>, per continuare ad utilizzare NoPR devi necessariamente creare una password sicura</p>
						<br />
						<input placeholder="nuova password" type="text" className="input---nb-outlined" style={{textAlign:'center'}} value={ this.state.field___password } onInput={(___e: any) => this.setState({ field___password: ___e.target.value })}/>
						<br />
						<input placeholder="ripeti nuova password" type="text" className="input---nb-outlined" style={{textAlign:'center'}} value={ this.state.field___password___repeat } onInput={(___e: any) => this.setState({ field___password___repeat: ___e.target.value })}/>
					</section>
				</IonContent>

				<IonFooter>
					<section className="container---footer-section">
						<button className="is-element is-button is-color---folly is-full-width" onClick={ this.submit___password_change }>
							<i className="fas fa-lock"></i>
							<h4>Salva Nuova Password</h4>
						</button>
					</section>
				</IonFooter>

			</IonModal>

		</>;
	}

//#endregion

}