import { interface___modal } from "../../interfaces/interface.modals";
import { type___ticket___type } from "../../types/types.types";

//	P R O P S

export interface props___MdTicketEditModal extends interface___modal {

	ticket_type: type___ticket___type,
	ticket_id: string | null

}

//	S T A T E

export interface state___MdTicketEditModal {

	alert___askForClose: boolean,

	me___props: any | null,
	me___props___is_loading: boolean,

	ticket___event_id: string | null,
	ticket___event_props: any | null,
	ticket___event_props___is_loading: boolean,

	ticket___type_reference: any | null,
	ticket___type_reference___is_selecting: boolean,

	ticket___person_name: string,
	ticket___notes: string,

	ticket___props___is_loading: boolean,

}

//	S T A T E   D E F A U L T

export const state___MdTicketEditModal___default: state___MdTicketEditModal = {

	alert___askForClose: false,

	me___props: null,
	me___props___is_loading: true,

	ticket___event_id: null,
	ticket___event_props: null,
	ticket___event_props___is_loading: true,

	ticket___type_reference: null,
	ticket___type_reference___is_selecting: true,

	ticket___person_name: '',
	ticket___notes: '',

	ticket___props___is_loading: true,

}