import React from 'react';
import { IonContent, IonModal, withIonLifeCycle } from '@ionic/react';

//	S T Y L E

import './md-cashdesk-error.scss';

//	S T A T E   -   P R O P S

import { props___MdCashDeskErrorModal } from './md-cashdesk-error.state';

//	C L A S S

class MdCashDeskErrorModal extends React.Component<props___MdCashDeskErrorModal, {}>
{

//#region 																							C O N F I G

	private readonly MdCashDeskErrorModal___modal_config: any = {
		backdropDismiss: false,
		handle: false,
		keyboardClose: false,
		showBackdrop: true,
	};

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonModal isOpen={ this.props.isOpen } { ...this.MdCashDeskErrorModal___modal_config }>
				<IonContent>
					<section className="md-cashdesk-error---container">
						<section color="danger" onClick={() => { this.props.event___onDidDismiss(); }}>
							<br />
							<section>
								<br />
								<i className="fas fa-exclamation-triangle"></i>
								<br />
								<br />
								<h1>Ticket non Valido</h1>
								<br />
							</section>
							<br />
							<br />
							<br />
							<h6>clicca ovunque per chiudere</h6>
							<br />	
						</section>
					</section>
				</IonContent>
			</IonModal>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(MdCashDeskErrorModal);