import React from 'react';
import { IonBackButton, IonButtons, IonCard, IonContent, IonHeader, IonItem, IonLabel, IonList, IonListHeader, IonNote, IonPage, IonToolbar, withIonLifeCycle } from '@ionic/react';

//	S T Y L E

import './sb-payments-users-overview.scss';

//	L I B S

import { lib_names } from '../../../libs/lib.names';

//	S T A T E   -   P R O P S

import { props___SbPaymentsUsersOverviewPage, state___SbPaymentsUsersOverviewPage, state___SbPaymentsUsersOverviewPage___default } from './sb-payments-users-overview.state';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';

//	S E R V I C E S

import { service_RestApiService } from '../../../services/service-api';
import { service_Router } from '../../../services/service-router';

//	C O M P O N E N T S

import ComListEmptyComponent from '../../../components/com-list-empty/com-list-empty';
import ComPaymentUserComponent from '../../../components/com-payment-user/com-payment-user';
import ComSpinnerComponent from '../../../components/com-spinner/com-spinner';

//	M O D A L S

import MdPaymentsUsersPayModal from '../../../modals/md-payments-users-pay/md-payments-users-pay';

//	C L A S S

class SbPaymentsUsersOverviewPage extends React.Component<props___SbPaymentsUsersOverviewPage, state___SbPaymentsUsersOverviewPage>
{

//#region 																							D E C L A R A T I O N S
		
	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _ROUTER: service_Router = new service_Router();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbPaymentsUsersOverviewPage,
	) {
		super(props);
		this.state = state___SbPaymentsUsersOverviewPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	read_props___user_and_payments = async () => {
		const ___temp___user___props: type___api___response = await this._API.users___read___single('id', this.state.user___id!);
		const ___temp___user___props___payments : type___api___response = await this._API.payments_users___read___single(this.state.user___id!);
		if (___temp___user___props && ___temp___user___props.response === 'success' && ___temp___user___props___payments && ___temp___user___props___payments.response === 'success') { console.log('404'); }
		this.setState({
			user___props: ___temp___user___props.data,
			user___props___payments: ___temp___user___props___payments.data,
			user___props___is_loading: false,
		});
	}

	read_props___user_payments___collection = async () => {
		const ___temp___user_payments___collection: type___api___response = await this._API.payments_users___read___list(this.state.user___id!);
		if (___temp___user_payments___collection && ___temp___user_payments___collection.response) {
			this.setState({
				user_payments___collection: (___temp___user_payments___collection.response === 'success') ? ___temp___user_payments___collection.data : [],
				user_payments___collection___is_loading: false,
			});
		}
	}

//#endregion

//#region 																							H A N D L E R S

	handle___MdPaymentsUsersPayModal___open = (___event_id: string) => {
		this.setState({
			modal___MdPaymentsUsersPayModal___visible: true,
			modal___MdPaymentsUsersPayModal___event_id: ___event_id,
		});
	}

	handle___MdPaymentsUsersPayModal___onDismiss = (___response: boolean | null = null) => {
		this.setState({
			modal___MdPaymentsUsersPayModal___visible: false,
			modal___MdPaymentsUsersPayModal___event_id: null,
		}, async () => {
			if (___response === true) {
				await this.read_props___user_and_payments();
				await this.read_props___user_payments___collection();
			}
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	ionViewDidEnter() : void {
		const ___user___id: string | null = this._ROUTER.get_id(this.props);
		if (___user___id) {
			this.setState({
				user___id: ___user___id,
			}, async () => {
				await this.read_props___user_and_payments();
				await this.read_props___user_payments___collection();
			});
		} else {
			console.log('problem reading info');
		}
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>

			<IonPage>

				<MdPaymentsUsersPayModal isOpen={ this.state.modal___MdPaymentsUsersPayModal___visible } user_id={ this.state.user___id } event_id={ this.state.modal___MdPaymentsUsersPayModal___event_id } event___onDidDismiss={ this.handle___MdPaymentsUsersPayModal___onDismiss }/>

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/payments/users"></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent>
					{(this.state.user___props___is_loading === true) ? <>
						<br />
						<br />
						<br />
						<br />
						<br />
						<ComSpinnerComponent size="small"/>
					</> : <>
						<section className="sb-payments-users-overview---container">

							<br />

							<div className="sb-payments-users-overview---user-image">
								<img src={ lib_names.endpoints.avatars + this.state.user___props['user_username'] } alt="avatar"/>
							</div>

							<br />

							<h5>{ this.state.user___props['user_username'] }</h5>
							<h3>
								{ this.state.user___props['user_name'] + ' ' + this.state.user___props['user_surname'] }
								{(this.state.user___props['user_name_displayed']) ? <>
									<br />
									<span>come "</span>
									{ this.state.user___props['user_name_displayed'] }
									<span>"</span>
								</> : <></>}
							</h3>

							<br />

							<IonListHeader>
								<IonLabel>Rientri dovuti</IonLabel>
							</IonListHeader>

							<br />

							{(this.state.user___props___payments['user_tickets'].length > 0) ? <>
								{this.state.user___props___payments['user_tickets'].map((___upp: any, ___upp___idx: number) => {
									return <>
										{(___upp['ticket_count_unpayed'] > 0) ? <>
											<IonCard key={ ___upp___idx } onClick={() => { this.handle___MdPaymentsUsersPayModal___open(___upp['ticket_event']); }}>
												<IonList>
													<IonItem button={ true } className="is-ion-item-button">
														<i className="fa-solid fa-calendar"></i>
														<IonLabel><b>{ ___upp['ticket_event_name'] }</b></IonLabel>
													</IonItem>
													<IonItem className="is-ion-item-button">
														<i className="fa-solid"></i>
														<IonLabel>Ammontare rientri dovuti</IonLabel>
														<IonNote><b>{ ___upp['ticket_count_unpayed'] * ___upp['ticket_payment_rate'] } €</b></IonNote>
													</IonItem>
												</IonList>
											</IonCard>
										</> : <></>}
										<br />
									</>;
								})}
							</> : <>
								<ComListEmptyComponent text="nessun rientro da pagare" />
							</>}

							<br />

							<IonListHeader>
								<IonLabel>Lista pagamenti effettuati</IonLabel>
							</IonListHeader>

							<br />

							{(this.state.user_payments___collection___is_loading === true) ? <>
								<br />
								<ComSpinnerComponent size="small"/>
								<br />
							</> : <>
								{(this.state.user_payments___collection.length > 0) ? <>
									{this.state.user_payments___collection.map((___up: any, ___up___idx: number) => {
										return <>
											<ComPaymentUserComponent key={ ___up___idx } payment___props={ ___up } />
											<br />
										</>;
									})}
								</> : <>
									<ComListEmptyComponent text="nessun pagamento effettuato" />
								</>}
								
							</>}

							<br />
							<br />
							<br />

						</section>
					</>}
				</IonContent>

			</IonPage>

		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbPaymentsUsersOverviewPage);