import React from 'react';
import { IonAlert, IonBackButton, IonButtons, IonCard, IonContent, IonHeader, IonItem, IonLabel, IonList, IonListHeader, IonPage, IonToolbar } from '@ionic/react';

//	S T Y L E

import './sb-settings-main.scss';

//	I N T E R F A C E S

import { interface___IonAlert } from '../../../interfaces/interface.alert';

//	P R O P S   -   S T A T E S

import { state___MdTicketEditModal, state___MdTicketEditModal___default } from './sb-settings-main.state';

//	S E R V I C E S

import { service_RestAuthService } from '../../../services/service-auth';

//	C L A S S

export default class SbSettingsMainPage extends React.Component<{}, state___MdTicketEditModal>
{

//#region 																							D E C L A R A T I O N S
		
	private readonly _AUTH: service_RestAuthService = new service_RestAuthService();

//#endregion

//#region 																							I N T E R F A C E S

	private readonly SbSettingsMainPage___IonAlert___logout_check: interface___IonAlert = {
		header: 'Aspetta',
		message: 'Vuoi davvero effettuare il logout?',
		buttons: [
			{
				text: 'Si',
				handler: () => { 
					this._AUTH.auth___logout();
					this.setState({ alert___logout_check___visibility: false });
				}
			},
			{
				text: 'Annulla',
				role: 'cancel',
				handler: () => {
					this.setState({ alert___logout_check___visibility: false });
				}
			}
		]
	};

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: {},
	)
	{
		super(props);
		this.state = state___MdTicketEditModal___default;
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{

		return <>

			<IonAlert isOpen={ this.state.alert___logout_check___visibility } { ...this.SbSettingsMainPage___IonAlert___logout_check }></IonAlert>

			<IonPage>

				<IonHeader className="ion-no-border" collapse="fade">
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/dashboard"></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent>

					<section className="sb-settings-main---container">

						<IonListHeader>
							<IonLabel>Account</IonLabel>
						</IonListHeader>
						
						<br />

						<IonCard>
							<IonList>
								<IonItem button={ true } className="is-ion-item-button" routerLink="/settings/profile">
									<i className="fas fa-user"></i>
									<IonLabel>Profilo</IonLabel>
								</IonItem>
								<IonItem button={ true } className="is-ion-item-button" routerLink="/settings/authentication">
									<i className="fas fa-lock"></i>
									<IonLabel>Autenticazione</IonLabel>
								</IonItem>
								<IonItem className="is-ion-item-button" onClick={() => { this.setState({ alert___logout_check___visibility: true }); }}>
									<i className="fas fa-power-off"></i>
									<IonLabel>Logout</IonLabel>
								</IonItem>
							</IonList>
						</IonCard>

						<br />
						<br />

						<IonListHeader>
							<IonLabel>Tema</IonLabel>
						</IonListHeader>

						<br />

						<IonCard>
							<IonList>
								<IonItem button={ true } className="is-ion-item-button" routerLink="/settings/theme">
									<i className="fas fa-palette"></i>
									<IonLabel>Impostazioni del tema</IonLabel>
								</IonItem>
							</IonList>
						</IonCard>

						{/**
							<br />
							<h2>Organizzazione</h2>
							<br />

							<IonCard>
								<IonList>
									<IonItem button={ true } className="is-ion-item-button" routerLink="/settings/organization">
										<i className="fas fa-badge"></i>
										<IonLabel>Impostazioni Organizzazione</IonLabel>
									</IonItem>
									<IonItem button={ true } className="is-ion-item-button" routerLink="/settings/organization/payments">
										<i className="fas fa-dollar"></i>
										<IonLabel>Impostazioni Pagamenti</IonLabel>
									</IonItem>
								</IonList>
							</IonCard>
						*/}

						<br />
						<br />

						<IonListHeader>
							<IonLabel>Info</IonLabel>
						</IonListHeader>

						<br />

						<IonCard>
							<IonList>
								<IonItem button={ true } className="is-ion-item-button" routerLink="/info/eula">
									<i className="fas fa-balance-scale"></i>
									<IonLabel>Termini e Condizioni</IonLabel>
								</IonItem>
								<IonItem button={ true } className="is-ion-item-button" routerLink="/info/eula">
									<i className="fas fa-cookie-bite"></i>
									<IonLabel>Cookies e Privacy</IonLabel>
								</IonItem>
								<IonItem button={ true } className="is-ion-item-button" routerLink="/info/app">
									<i className="fas fa-code-branch"></i>
									<IonLabel>Versione App</IonLabel>
								</IonItem>
								<IonItem button={ true } className="is-ion-item-button" routerLink="/info/copyright">
									<i className="fas fa-copyright"></i>
									<IonLabel>Copyright</IonLabel>
								</IonItem>
								<IonItem button={ true } className="is-ion-item-button" routerLink="/info/licences">
									<i className="fas fa-key"></i>
									<IonLabel>Licenze</IonLabel>
								</IonItem>
							</IonList>
						</IonCard>

					</section>

				</IonContent>

			</IonPage>

		</>;

	}

//#endregion

}