import React from 'react';
import { IonBackButton, IonButtons, IonCard, IonContent, IonHeader, IonItem, IonLabel, IonList, IonListHeader, IonNote, IonPage, IonRefresher, IonToolbar, RefresherEventDetail, withIonLifeCycle } from '@ionic/react';

//	S T Y L E

import './sb-users-list.scss';

//	S T A T E   -   P R O P S

import { state___SbEventsListPage, state___SbEventsListPage___default } from './sb-users-list.state';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';

//	S E R V I C E S

import { service_RestApiService } from '../../../services/service-api';

//	C O M P O N E N T S

import ComSpinnerMoreComponent from '../../../components/com-spinner-more/com-spinner-more';
import ComSpinnerRefreshComponent from '../../../components/com-spinner-refresh/com-spinner-refresh';

//	C L A S S

class SbUsersListPage extends React.Component<{}, state___SbEventsListPage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: object,
	)
	{
		super(props);
		this.state = state___SbEventsListPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	read_props___me = async () => {
		const ___temp___me___props: type___api___response = await this._API.users___read___single('me', null);
		if (!___temp___me___props || ___temp___me___props.response !== 'success' || ___temp___me___props.data === null) { return; }
		this.setState({
			me___props: ___temp___me___props.data,
			me___props___is_loading: false,
		});
	}

	read_props___users___collection = async () => {
		const ___temp___user___collection___admins: type___api___response = await this._API.users___read___multi('role', 'admin');
		const ___temp___user___collection___prs: type___api___response = await this._API.users___read___multi('role', 'pr');
		const ___temp___user___collection___cashdesks: type___api___response = await this._API.users___read___multi('role', 'cashdesk');
		this.setState({
			user___collection___admins: ___temp___user___collection___admins.data,
			user___collection___prs: ___temp___user___collection___prs.data,
			user___collection___cashdesks: ___temp___user___collection___cashdesks.data,
			user___collection___is_loading: false
		});
	}

//#endregion

//#region 																							H A N D L E R S

	handle___page_refresh = (___e: CustomEvent<RefresherEventDetail>) => {
		this.read_props___users___collection();
		___e.detail.complete();
	}

	handle___user___options = async (___event___id: string) => {
		this.setState({
			actionSheet___user_options___target: ___event___id,
			actionSheet___user_options: true
		}); 
	}

//#endregion

//#region 																							L I F E C Y C L E

	async ionViewWillEnter() : Promise<void>
	{
		await this.read_props___me();
		await this.read_props___users___collection();
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{

		return <>

			<IonPage>

				<IonHeader className="ion-no-border" collapse="fade">
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/dashboard"></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent>

					<IonRefresher slot="fixed" onIonRefresh={ this.handle___page_refresh }>
						<ComSpinnerRefreshComponent/>
					</IonRefresher>

					<section className="sb-users-list---container">

						<IonListHeader>
							<IonLabel>
								<section style={{display:'flex',justifyContent:'space-between'}}>
									<span>
										<i className="fas fa-star" style={{paddingRight:'15pt'}}></i>
										<span>Amministratori</span>
									</span>
									<span style={{paddingRight:'10pt',opacity:0.5,transform:'scale(0.75)'}}>
										{ this.state.user___collection___admins.length }
									</span>
								</section>
							</IonLabel>
						</IonListHeader>

						<p>Questo utente ha il pieno controllo e la gestione di staff, impostazioni e funzionalità, inoltre può creare e modificare gli eventi</p>

						<IonCard>
							{(this.state.user___collection___is_loading !== true) ? <>
								<IonList>
									{this.state.user___collection___admins.map((___u_a: any, ___u_a___idx: number) => {
										return <>
											<IonItem key={ ___u_a___idx } routerLink={ '/users/edit/' + ___u_a['user_id'] }>
												<IonLabel>
													{ ___u_a['user_name'] + ' ' + ___u_a['user_surname'] }
													{(___u_a['user_name_displayed'] && ___u_a['user_name_displayed'].length > 0) ? <>
														<span style={{opacity:'0.5',fontWeight:'bold'}}> ({ ___u_a['user_name_displayed'] })</span>
													</> : <></>}
												</IonLabel>
												{(___u_a['user_role_is___admin'] === true) ? <><IonNote slot="end"><i className="fas fa-star" style={{transform:'scale(0.65)'}}></i></IonNote></> : <></>}
												{(___u_a['user_role_is___pr'] === true) ? <><IonNote slot="end"><i className="fas fa-user" style={{transform:'scale(0.65)'}}></i></IonNote></> : <></>}
												{(___u_a['user_role_is___cashdesk'] === true) ? <><IonNote slot="end"><i className="fas fa-cash-register" style={{transform:'scale(0.65)'}}></i></IonNote></> : <></>}
											</IonItem>
										</>;
									})}
									{(this.state.me___props___is_loading === false && this.state.me___props && this.state.me___props['user_role_is___admin'] === true) ? <>
										<IonItem button={ true } routerLink="/users/edit/new" style={{textAlign:'right',fontWeight:'bold',opacity:'0.5'}}>
											<IonLabel>crea utente</IonLabel>
										</IonItem>
									</> : <></>}
								</IonList>
							</> : <>
								<ComSpinnerMoreComponent/>
							</>}
						</IonCard>

						<br />

						<IonListHeader>
							<IonLabel>
								<section style={{display:'flex',justifyContent:'space-between'}}>
									<span>
										<i className="fas fa-cash-register" style={{paddingRight:'15pt'}}></i>
										<span>Cashdesks</span>
									</span>
									<span style={{paddingRight:'10pt',opacity:0.5,transform:'scale(0.75)'}}>
										{ this.state.user___collection___cashdesks.length }
									</span>
								</section>
							</IonLabel>
						</IonListHeader>

						<p>Questo utente ha, oltre il ruolo di <b>PR</b> anche quello di poter validare i ticket con un lettore di codici QR</p>

						<IonCard>
							{(this.state.user___collection___is_loading !== true) ? <>
								<IonList>
									{this.state.user___collection___cashdesks.map((___u_c: any, ___u_c___idx: number) => {
										return <>
											<IonItem key={ ___u_c___idx } routerLink={ '/users/edit/' + ___u_c['user_id'] }>
												<IonLabel>
													{ ___u_c['user_name'] + ' ' + ___u_c['user_surname'] }
													{(___u_c['user_name_displayed'] && ___u_c['user_name_displayed'].length > 0) ? <>
														<span style={{opacity:'0.5',fontWeight:'bold'}}> ({ ___u_c['user_name_displayed'] })</span>
													</> : <></>}
												</IonLabel>
												{(___u_c['user_role_is___admin'] === true) ? <><IonNote slot="end"><i className="fas fa-star" style={{transform:'scale(0.65)'}}></i></IonNote></> : <></>}
												{(___u_c['user_role_is___pr'] === true) ? <><IonNote slot="end"><i className="fas fa-user" style={{transform:'scale(0.65)'}}></i></IonNote></> : <></>}
												{(___u_c['user_role_is___cashdesk'] === true) ? <><IonNote slot="end"><i className="fas fa-cash-register" style={{transform:'scale(0.65)'}}></i></IonNote></> : <></>}
											</IonItem>
										</>;
									})}
									{(this.state.me___props___is_loading === false && this.state.me___props && this.state.me___props['user_role_is___admin'] === true) ? <>
										<IonItem button={ true } routerLink="/users/edit/new" style={{textAlign:'right',fontWeight:'bold',opacity:'0.5'}}>
											<IonLabel>crea utente</IonLabel>
										</IonItem>
									</> : <></>}
								</IonList>
							</> : <>
								<ComSpinnerMoreComponent/>
							</>}
						</IonCard>

						<br />


						<IonListHeader>
							<IonLabel>
								<section style={{display:'flex',justifyContent:'space-between'}}>
									<span>
										<i className="fas fa-user" style={{paddingRight:'15pt'}}></i>
										<span>PR</span>
									</span>
									<span style={{paddingRight:'10pt',opacity:0.5,transform:'scale(0.75)'}}>
										{ this.state.user___collection___prs.length }
									</span>
								</section>
							</IonLabel>
						</IonListHeader>

						<p>Questo utente ha la possibilità di creare ticket ed inviti</p>

						<IonCard>
							{(this.state.user___collection___is_loading !== true) ? <>
								<IonList>
									{this.state.user___collection___prs.map((___u_p: any, ___u_p___idx: number) => {
										return <>
											<IonItem key={ ___u_p___idx } routerLink={ '/users/edit/' + ___u_p['user_id'] }>
												<IonLabel>
													{ ___u_p['user_name'] + ' ' + ___u_p['user_surname'] }
													{(___u_p['user_name_displayed'] && ___u_p['user_name_displayed'].length > 0) ? <>
														<span style={{opacity:'0.5',fontWeight:'bold'}}> ({ ___u_p['user_name_displayed'] })</span>
													</> : <></>}
												</IonLabel>
												{(___u_p['user_role_is___admin'] === true) ? <><IonNote slot="end"><i className="fas fa-star" style={{transform:'scale(0.65)'}}></i></IonNote></> : <></>}
												{(___u_p['user_role_is___pr'] === true) ? <><IonNote slot="end"><i className="fas fa-user" style={{transform:'scale(0.65)'}}></i></IonNote></> : <></>}
												{(___u_p['user_role_is___cashdesk'] === true) ? <><IonNote slot="end"><i className="fas fa-cash-register" style={{transform:'scale(0.65)'}}></i></IonNote></> : <></>}
											</IonItem>
										</>;
									})}
									{(this.state.me___props___is_loading === false && this.state.me___props && this.state.me___props['user_role_is___admin'] === true) ? <>
										<IonItem button={ true } routerLink="/users/edit/new" style={{textAlign:'right',fontWeight:'bold',opacity:'0.5'}}>
											<IonLabel>crea utente</IonLabel>
										</IonItem>
									</> : <></>}
								</IonList>
							</> : <>
								<ComSpinnerMoreComponent/>
							</>}
						</IonCard>

						<br />

					</section>

				</IonContent>

			</IonPage>

		</>;

	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbUsersListPage);