import React from 'react'

//	S T Y L E

import './com-event.scss'

//	L I B S

import { lib_names } from '../../libs/lib.names';

//	S T A T E S   -   P R O P S

import { props___ComEventComponent } from './com-event.state';

//	F U N C S

import { funcs_datetime } from '../../funcs/funcs.datetime';

//	C L A S S

export default class ComEventComponent extends React.Component<props___ComEventComponent, {}>
{

//#region 																							C O N S T R U C T O R

	private readonly funcs___datetime: funcs_datetime = new funcs_datetime();

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<section className="com-event---container">
				{(this.props.event___props['event_poster']) ? <>
					<img src={ this.props.event___props['event_poster'] }/>
				</> : <></>}
				<section>
					{/*	
						<span *ngIf="this.props.event___props['poi_verified'] === true" className="chip---verified">
							<i className="fas fa-check"></i>
							<span>Proprietario verificato</span>
						</span>
					*/}
					<h5>
						<span>{ this.props.event___props['event_name'] }</span>
					</h5>
					<p>
						<i className="fas fa-calendar-alt"></i>
						<span>{ this.funcs___datetime.datetime___get___date(this.props.event___props['event_datetime_begin']) }</span>
						<span> alle </span>
						<span>{ this.funcs___datetime.datetime___get___time(this.props.event___props['event_datetime_begin']) }</span>
					</p>
				</section>
			</section>
		</>;
	}

//#endregion

}