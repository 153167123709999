//	E X P O R T   L I B

export class funcs_numbers
{

//#region 																							F O R M A T   N U M B E R

	private number___format_number(___value: number, ___decimal: number) : string
	{
		const ___value___formatter: Intl.NumberFormat = new Intl.NumberFormat('de-DE', {
			minimumFractionDigits: ___decimal,
			maximumFractionDigits: ___decimal,
		});
		return ___value___formatter.format(___value) as string;
	}

//#endregion

//#region 																							N U M BV E R   O R   S Y M B O L

	public number___number_or_symbol(___value: number, ___symbol: string = '-') : string
	{
		if (!___value) { return ___symbol; }
		if (___value <= 0) { return ___symbol; }
		return ___value.toString() as string;
	}

//#endregion

//#region 																							F O R M A T   P R I C E

	public number___format_price(___value: string | number, ___symbol: string | null = '€') : string
	{
		const ___parsed_price: number = (typeof ___value === 'string') ? parseFloat(___value) : ___value;
		if (isNaN(___parsed_price) === true) { return '0'; }
		const ___parsed_price___formatted: string = this.number___format_number(___parsed_price, 2);
		return ___parsed_price___formatted as string + ' ' + ((___symbol !== null) ? ___symbol : '');
	}

//#endregion

//#region 																							N U M B E R S

	private number___format_decimal(___value: number, ___decimal: number = 0) : string
	{
		const ___value___formatter: Intl.NumberFormat = new Intl.NumberFormat('de-DE', {
			minimumFractionDigits: ___decimal,
			maximumFractionDigits: ___decimal,
		});
		return ___value___formatter.format(___value) as string;
	}

//#endregion

//#region 																							D I S T A N C E

	public number___format_as_string___distance(___value: number | string) : string
	{
		
		const ___distance: number = typeof ___value === 'string' ? parseFloat(___value) : ___value;

		if (isNaN(___distance)) { return '-'; }
		if (___distance < 1) { return Math.floor(___distance * 1000).toString() + ' metri'; }
		else if (___distance < 10) { return this.number___format_decimal(___distance, 2).toString() + ' km'; }
		else { return this.number___format_decimal(___distance, 0).toString() + ' km'; }
	
	}

//#endregion

//#region 																							P A D

	public pad___twoDigits(___value: number) : string
	{
		const ___value___to_return: string =  ___value.toString().padStart(2, '0');
		return ___value___to_return;
	}

//#endregion












}