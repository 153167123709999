import React from 'react';
import { IonBackButton, IonButtons, IonCard, IonContent, IonHeader, IonItem, IonLabel, IonList, IonListHeader, IonNote, IonPage, IonRefresher, IonToolbar, RefresherEventDetail, withIonLifeCycle } from '@ionic/react';

//	S T Y L E

import './sb-analytics-me.scss';

//	F U N C S

import { funcs_numbers } from '../../../funcs/funcs.numbers';

//	S T A T E   -   P R O P S

import { props___SbEventsViewPage, state___SbEventsViewPage, state___SbEventsViewPage___default } from './sb-analytics-me.state';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';

//	S E R V I C E S

import { service_RestApiService } from '../../../services/service-api';
import { service_Router } from '../../../services/service-router';

//	C O M P O N E N T S

import ComBannerHintComponent from '../../../components/com-banner-hint/com-banner-hint';
import ComEventComponent from '../../../components/com-event/com-event';
import ComEventTimeScheduleComponent from '../../../components/com-event-timeschedule/com-event-timeschedule';
import ComSpinnerComponent from '../../../components/com-spinner/com-spinner';
import ComSpinnerRefreshComponent from '../../../components/com-spinner-refresh/com-spinner-refresh';

//	C L A S S

class SbAnalyticsMePage extends React.Component<props___SbEventsViewPage, state___SbEventsViewPage>
{

//#region 																							D E C L A R A T I O N S
		
	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _ROUTER: service_Router = new service_Router();

	private readonly funcs____numbers: funcs_numbers = new funcs_numbers();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbEventsViewPage,
	) {
		super(props);
		this.state = state___SbEventsViewPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	read_props___event = async () => {
		const ___temp___event___props: type___api___response = await this._API.events___read___single(this.state.event___id);
		if (!___temp___event___props || ___temp___event___props.response !== 'success' || ___temp___event___props.data === null) { console.log('404'); }
		this.setState({
			event___props: ___temp___event___props.data,
			event___props___is_loading: false
		}, async () => {
			await this.rear_props___chart___analytics_tickets();
		});
	}

	rear_props___chart___analytics_tickets = async () => {
		const ___a_chart___event_props: any = await this._API.analytics___read___users(this.state.event___id, 'standard', true);
		if (___a_chart___event_props.data === null) { return; }
		this.setState({
			tickets_analytics___props: ___a_chart___event_props.data,
			tickets_analytics___props___is_loading: false,
		});
	}

//#endregion

//#region 																							H A N D L E R S

	handle___page_refresh = async (___e: CustomEvent<RefresherEventDetail>) => {
		await this.read_props___event();
		await this.rear_props___chart___analytics_tickets();
		___e.detail.complete();
	}

//#endregion

//#region 																							L I F E C Y C L E

	ionViewDidEnter() : void {
		const ___event___id: string | null = this._ROUTER.get_id(this.props);
		this.setState({
			event___id: ___event___id!,
		}, async () => {
			await this.read_props___event();
		});
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{

		return <>

			<IonPage>

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/dashboard"></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent>

					<IonRefresher slot="fixed" onIonRefresh={ this.handle___page_refresh }>
						<ComSpinnerRefreshComponent/>
					</IonRefresher>

					{(this.state.event___props && this.state.event___props['event_is_now'] === true) ? <>
						<section style={{padding:'10pt'}}>
							<ComBannerHintComponent text="Attenzione, l'evento è in corso e le statistiche potrebbero non essere accurate. Per le statistiche accurate attendi la fine dell'evento"/>
						</section>
					</> : <></>}

					<IonListHeader>
						<IonLabel>Panoramica Evento</IonLabel>
					</IonListHeader>

					<section className="sb-analytics-event---event">
						{(this.state.event___props___is_loading === true) ? <>
							<ComSpinnerComponent size="small"/>
						</> : <>
							<ComEventTimeScheduleComponent event___props={ this.state.event___props } />
							<br />
							<IonCard>
								<ComEventComponent event___props={ this.state.event___props }/>
							</IonCard>
						</>}

					</section>

					<br />

					<IonListHeader>
						<IonLabel>Rendimento globale</IonLabel>
					</IonListHeader>

					<br />

					<section className="sb-analytics-event---event-resume">
						<IonCard>
							{(this.state.tickets_analytics___props___is_loading === false) ? <>
								<IonList>
									{(this.state.event___props['event_settings___tickets_standards'] === true) ? <>
										<IonItem className="is-ion-item-button">
											<i className="fa-solid fa-qrcode"></i>
											<IonLabel>Tickets timbrati</IonLabel>
											<IonNote>
												<b>{ this.funcs____numbers.number___number_or_symbol(this.state.tickets_analytics___props['tickets___total_standard_used'], 'nessuno') }</b>
												<span> di </span>
												<b>{ this.funcs____numbers.number___number_or_symbol(this.state.tickets_analytics___props['tickets___total_standard']) }</b>
											</IonNote>
										</IonItem>
									</> : <></>}
									{(this.state.event___props['event_settings___tickets_lists'] === true) ? <>
										<IonItem className="is-ion-item-button">
											<i className="fa-solid fa-calendar-week"></i>
											<IonLabel>Inviti utilizzati</IonLabel>
											<IonNote>
												<b>{ this.funcs____numbers.number___number_or_symbol(this.state.tickets_analytics___props['tickets___total_invite_used'], 'nessuno') }</b>
												<span> di </span>
												<b>{ this.funcs____numbers.number___number_or_symbol(this.state.tickets_analytics___props['tickets___total_invite'], '-') }</b>
											</IonNote>
										</IonItem>
									</> : <></>}
									{/*
										{(this.state.event___props['event_settings_tickets_online'] === true) ? <>
											<IonItem className="is-ion-item-button">
												<i className="fa-solid fa-globe"></i>
												<IonLabel>Online</IonLabel>
											</IonItem>
										</> : <></>}
									*/}
								</IonList>
							</> : <>
								<br />
								<br />
								<ComSpinnerComponent size="small"/>
								<br />
								<br />
							</>}
						</IonCard>
					</section>

					<br />

					<IonListHeader>
						<IonLabel>Il tuo Rendimento</IonLabel>
					</IonListHeader>

					<br />

					<section className="sb-analytics-event---event-resume">

						{(this.state.tickets_analytics___props___is_loading === true) ? <>
						
							<br />
							<br />
							<ComSpinnerComponent size="small"/>
							<br />
							<br />

						</> : <>

							{(this.state.event___props['event_settings___tickets_lists'] === true) ? <>
								<IonCard>
									<IonList className='sb-analytics-event---pr-list'>
										<IonItem className="is-ion-item-button">
											<i className="fa-solid fa-qrcode"></i>
											<IonLabel>Inviti Inviati</IonLabel>
											<IonNote><b>{ this.funcs____numbers.number___number_or_symbol(this.state.tickets_analytics___props['tickets___by_pr'][0]['user_tickets']['invites_total']) }</b></IonNote>
										</IonItem>
										<IonItem className="is-ion-item-button">
											<i className="fa-solid "></i>
											<IonLabel><b>di cui usati</b></IonLabel>
											<IonNote><b>{ this.funcs____numbers.number___number_or_symbol(this.state.tickets_analytics___props['tickets___by_pr'][0]['user_tickets']['invites_used']) }</b></IonNote>
										</IonItem>
									</IonList>
								</IonCard>
							</> : <></>}

							<br />

							{(this.state.event___props['event_settings___tickets_standards'] === true) ? <>
								<IonCard>
									<IonList className='sb-analytics-event---pr-list'>
										<IonItem className="is-ion-item-button">
											<i className="fa-solid fa-calendar-week"></i>
											<IonLabel>Tickets Emessi</IonLabel>
											<IonNote><b>{ this.funcs____numbers.number___number_or_symbol(this.state.tickets_analytics___props['tickets___by_pr'][0]['user_tickets']['standards_total']) }</b></IonNote>
										</IonItem>
										<IonItem className="is-ion-item-button">
											<i className="fa-solid "></i>
											<IonLabel><b>di cui timbrati</b></IonLabel>
											<IonNote><b>{ this.funcs____numbers.number___number_or_symbol(this.state.tickets_analytics___props['tickets___by_pr'][0]['user_tickets']['standards_used']) }</b></IonNote>
										</IonItem>
									</IonList>
								</IonCard>
							</> : <></>}
						
						</>}

					</section>

				</IonContent>

			</IonPage>

		</>;

	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbAnalyticsMePage);