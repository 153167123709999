//	L I B S

import { lib_names } from "../libs/lib.names";

//	T Y P E S

import { type___theme } from "../types/types.themes";

//	E X P O R T   C L A S S

export class theme_constructor
{

//#region 																							T H E M E   G E T

	theme___get() : type___theme
	{
		const ___theme___loaded: string | null = localStorage.getItem(lib_names.localStorage.theme___type);
		const ___theme___loaded___parsed: type___theme = (___theme___loaded === null || (___theme___loaded && !(___theme___loaded === 'dark') && !(___theme___loaded === 'light'))) ? 'light' : localStorage.getItem(lib_names.localStorage.theme___type) as type___theme;


		console.log(___theme___loaded___parsed)

		return ___theme___loaded___parsed as type___theme;
	}

//#endregion

//#region 																							T H E M E   L O A D

	theme___load() : void
	{
		const ___theme___loaded: string | null = localStorage.getItem(lib_names.localStorage.theme___type);
		if (___theme___loaded === null || (___theme___loaded && !(___theme___loaded === 'dark') && !(___theme___loaded === 'light'))) {
			this.theme___set('light'); this.theme___load(); return;
		}
		document.body.setAttribute('data-theme', ___theme___loaded);
		return;
	}

//#endregion

//#region 																							T H E M E   S E T

	theme___set(___new_theme: type___theme) : void
	{
		localStorage.setItem(lib_names.localStorage.theme___type, ___new_theme);
		this.theme___load();
		return;
	}

//#endregion

}