import React from 'react';
import { IonBackButton, IonButtons, IonContent, IonHeader, IonLabel, IonListHeader, IonPage, IonSegment, IonSegmentButton, IonToolbar } from '@ionic/react';

//	S T Y L E

import './sb-settings-theme.scss';

//	T Y P E S

import { type___theme } from '../../../types/types.themes';

//	P R O P S   -   S T A T E S

import { props___SbSettingsThemePage, state___SbSettingsThemePage, state___SbSettingsThemePage___default } from './sb-settings-theme.state';

//	T H E M E 

import { theme_constructor } from '../../../theme/theme.constructor';

//	C L A S S

export default class SbSettingsThemePage extends React.Component<props___SbSettingsThemePage, state___SbSettingsThemePage>
{

//#region 																							D E C L A R A T I O N S
		
	private readonly theme___constructor: theme_constructor = new theme_constructor();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public _PROPS: props___SbSettingsThemePage,
	)
	{
		super(_PROPS);
		this.state = state___SbSettingsThemePage___default;
	}

//#endregion

//#region 																							H A N D L E R S

	handle___change_theme = (___e: any) => {
		const ___theme_selected: type___theme = ___e.detail.value as type___theme;
		this.theme___constructor.theme___set(___theme_selected);
	}

//#endregion

//#region 																							L I F E C Y C L E

	async componentDidMount() : Promise<void>
	{
		const ___theme: type___theme = this.theme___constructor.theme___get();
		this.setState({
			field___theme_selected: ___theme
		});
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{

		return <>

			<IonPage>

				<IonHeader className="ion-no-border" collapse="fade">
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/settings"></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent>

					<section className="sb-settings-authentication---container">

						<IonListHeader>
							<IonLabel>Cambia Tema</IonLabel>
						</IonListHeader>

						<br />
						<br />

						<IonSegment value={ this.state.field___theme_selected } onIonChange={ this.handle___change_theme }>

							<IonSegmentButton value="light">
								<IonLabel>Chiaro</IonLabel>
							</IonSegmentButton>
							
							<IonSegmentButton value="dark">
								<IonLabel>Scuro</IonLabel>
							</IonSegmentButton>
						
						</IonSegment>










					</section>

				</IonContent>

			</IonPage>

		</>;

	}

//#endregion

}