import { RouteComponentProps } from "react-router";
import { interface___page } from "../../../interfaces/interface.page";

//	P R O P S

export interface props___SbPaymentsUsersOverviewPage extends RouteComponentProps<{ id: string; }>, interface___page {

}

//	S T A T E

export interface state___SbPaymentsUsersOverviewPage {

	user___id: string | null,
	user___props: any | null,
	user___props___payments: any | null,
	user___props___is_loading: boolean,

	user_payments___collection: any[],
	user_payments___collection___is_loading: boolean,

	modal___MdPaymentsUsersPayModal___visible: boolean,
	modal___MdPaymentsUsersPayModal___event_id: string | null,

}

//	S T A T E   D E F A U L T

export const state___SbPaymentsUsersOverviewPage___default: state___SbPaymentsUsersOverviewPage = {

	user___id: null,
	user___props: null,
	user___props___payments: null,
	user___props___is_loading: true,

	user_payments___collection: [],
	user_payments___collection___is_loading: true,

	modal___MdPaymentsUsersPayModal___visible: false,
	modal___MdPaymentsUsersPayModal___event_id: null,

}