//	P R O P S

import { type___theme } from "../../../types/types.themes";

export interface props___SbSettingsThemePage {

}

//	S T A T E

export interface state___SbSettingsThemePage {

	field___theme_selected: type___theme, 







}

//	S T A T E   D E F A U L T

export const state___SbSettingsThemePage___default: state___SbSettingsThemePage = {

	field___theme_selected: 'light', 
	


	
	




}