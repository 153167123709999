import React from 'react'

//	S T Y L E

import './com-event-micro.scss'

//	S T A T E S   -   P R O P S

import { props___ComEventMicroComponent } from './com-event-micro.state';

//	F U N C S

import { funcs_datetime } from '../../funcs/funcs.datetime';

//	C L A S S

export default class ComEventMicroComponent extends React.Component<props___ComEventMicroComponent, {}>
{

//#region 																							C O N S T R U C T O R

	private readonly funcs___datetime: funcs_datetime = new funcs_datetime();

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<section className="com-event-micro---container">
				<div>
					{(this.props.event___props['event_ended'] === false) ? <>
						{(this.props.event___props['event_is_now'] === true) ? <>
							<h6 style={{fontSize:'large'}}>In corso</h6>
						</> : <>
							<h6>Inizia il { this.funcs___datetime.datetime___get___date(this.props.event___props['event_datetime_begin']) } alle { this.funcs___datetime.datetime___get___time(this.props.event___props['event_datetime_end']) }</h6>
						</>}
					</> : <>
						<h6>Terminato il { this.funcs___datetime.datetime___get___date(this.props.event___props['event_datetime_begin']) } alle { this.funcs___datetime.datetime___get___time(this.props.event___props['event_datetime_end']) }</h6>
					</>}
					<h5><span>{ this.props.event___props['event_name'] }</span></h5>
				</div>
			</section>
		</>;
	}

//#endregion

}