import { interface___page } from "../../interfaces/interface.page";
import { type___ticket___object } from "../../types/types.types";

//	P R O P S

export interface props___SbCashdeskPage extends interface___page {
	
}

//	S T A T E

export interface state___SbCashdeskPage {

	cashdesk___available: boolean | null,

	event___props: any | null,
	event___props___is_loading: boolean,

	section___selected: 'scanner' | 'list',
	section___selected___list_view: 'all' | 'unused',

	qrCode___found: boolean,
	qrCode___found___object: type___ticket___object | null,
	qrCode___result___success: boolean,
	qrCode___result___error: boolean,

	tickets___collection: any[],
	tickets___collection___is_loading: boolean,

}

//	S T A T E   D E F A U L T

export const state___SbCashdeskPage___default: state___SbCashdeskPage = {

	cashdesk___available: null,

	event___props: null,
	event___props___is_loading: true,

	section___selected: 'scanner',
	section___selected___list_view: 'unused',

	qrCode___found: false,
	qrCode___found___object: null,
	qrCode___result___success: false,
	qrCode___result___error: false,

	tickets___collection: [],
	tickets___collection___is_loading: true,

}