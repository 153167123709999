import React from 'react';
import { IonBackButton, IonBadge, IonButton, IonButtons, IonCard, IonContent, IonFooter, IonHeader, IonItem, IonLabel, IonList, IonListHeader, IonPage, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, IonToolbar, withIonLifeCycle } from '@ionic/react';
import { IDetectedBarcode, Scanner } from '@yudiel/react-qr-scanner';

//	S T Y L E

import './sb-cashdesk.scss';

//	S T A T E   -   P R O P S

import { props___SbCashdeskPage, state___SbCashdeskPage, state___SbCashdeskPage___default } from './sb-cashdesk.state';

//	L I B S

import { lib_names } from '../../libs/lib.names';
import { lib_names_shared } from '../../libs/lib.names.shared';

//	F U N C S

import { funcs_tickets } from '../../funcs/funcs.tickets';

//	T Y P E S

import { type___api___response } from '../../types/types.api-response';
import { type___ticket___object } from '../../types/types.types';

//	S E R V I C E S

import { service_RestApiService } from '../../services/service-api';

//	C O M P O N E N T S

import ComEventMicroComponent from '../../components/com-event-micro/com-event-micro';
import ComSpinnerComponent from '../../components/com-spinner/com-spinner';

//	M O D A L S

import MdCashDeskCheckoutModal from '../../modals/md-cashdesk-checkout/md-cashdesk-checkout';
import MdCashDeskErrorModal from '../../modals/md-cashdesk-error/md-cashdesk-error';
import MdCashDeskSuccessModal from '../../modals/md-cashdesk-success/md-cashdesk-success';





//	C L A S S

class SbCashdeskPage extends React.Component<props___SbCashdeskPage, state___SbCashdeskPage>
{

//#region 																							D E C L A R A T I O N S
		
	private readonly _API: service_RestApiService = new service_RestApiService();

	private readonly funcs___tickets: funcs_tickets = new funcs_tickets();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbCashdeskPage,
	)
	{
		super(props);
		this.state = state___SbCashdeskPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	read_props___event = async () => {
		const ___stored___event_id: string | null = localStorage.getItem(lib_names.localStorage.user___event_selected);
		if (___stored___event_id !== null)
		{
			const ___temp___selected_event_props: type___api___response = await this._API.events___read___single(___stored___event_id);
			this.setState({
				event___props: ___temp___selected_event_props.data,
				event___props___is_loading: false
			}, () => {
				this.setState({
					cashdesk___available: this.state.event___props['event_is_now']
				}, async () => {
					if (this.state.cashdesk___available === true) {
						await this.read_props___tickets_collection();
					}
				});
			});
		}
		else
		{
			this.setState({
				event___props___is_loading: false
			});
		}
	}

	read_props___tickets_collection = async () => {
		const ___tickets___list: type___api___response = await this._API.cashdesk___tickets_list(this.state.event___props['event_id']);
		this.setState({
			tickets___collection: ___tickets___list.data,
			tickets___collection___is_loading: false
		});
	}

//#endregion

//#region 																							H A N D L E R S

	handle___override___cashdesk_available = () => {
		this.setState({
			cashdesk___available: true,
		}, async () => {
			await this.read_props___tickets_collection();
		});
	}

	handle___onQrCodeScan = async (___result: IDetectedBarcode[]) => {
		if (___result && ___result.length > 0)
		{

			let ___result___text: string = (___result[0]['format'] === 'qr_code') ? ___result[0]['rawValue'] : '';
				___result___text = ___result___text.replace(lib_names_shared.endpoints.qrs_nopr_invite, '');
				___result___text = ___result___text.replace(lib_names_shared.endpoints.qrs_nopr_online, '');
				___result___text = ___result___text.replace(lib_names_shared.endpoints.qrs_nopr_standard, '');



		/**
		 * 
		 * 
		 * 
		 * 				B E G I N      - >      T O   R E M O V E
		 * 
		 * 
		 * 
		 */



			if (___result___text.includes(';'))
			{

				const ___old_ticket___parser: string[] = ___result___text.split(';');
				const ___old_ticket___v: type___ticket___object = {
					ticket_id: null,
					ticket_event: ___old_ticket___parser[0],
					ticket_pr: (___old_ticket___parser[1]) ? ___old_ticket___parser[1] : null,
					ticket_type: 'invite',
				};

				___result___text = this.funcs___tickets.ticket___encrypt___from_object(___old_ticket___v);

			}



		/**
		 * 
		 * 
		 * 
		 * 				E N D     - >      T O   R E M O V E
		 * 
		 * 
		 * 
		 */



			const ___qrCode_found___temp: type___api___response = await this._API.cashdesk___ticket_preload(___result___text);
			
			if (___qrCode_found___temp && ___qrCode_found___temp.response === 'success') {
				this.setState({
					qrCode___found: true,
					qrCode___found___object: ___qrCode_found___temp.data as type___ticket___object,
				});
			} else {
				this.handle___onQrCodeScan___error();
			}

		}
	}

	handle___onQrCodeScan___error = () => {
		console.log('error')
		this.setState({
			qrCode___result___error: true,
		});
	}

	handle___onQrCodeScan___dismiss_modal = (___success: boolean = false) => {
		this.setState({
			qrCode___found: false,
			qrCode___found___object: null,
			qrCode___result___success: ___success,
		}, async () => {
			await this.read_props___tickets_collection();
		});
	}

	handle___list___select_letter = (___letter: string) => {
		const ___scroller___container: HTMLElement = document.getElementById('sb-cashdesk-page---list-container')!;
		const ___scroller___target: HTMLElement = document.getElementById('sb-cashdesk-page---list-element---' + ___letter.toLowerCase())!;
		___scroller___container.scrollTo({ top: ___scroller___target.offsetTop, behavior: 'smooth' });
	}

	handle___list___change_viewable = (___event: any) => {
		this.setState({
			section___selected___list_view: ___event.detail.value,
		});
	}

	handle___list___onTicketCollectionItemClick = (___ticket_props: any) => {
		const ___temp___ticket_object: type___ticket___object = {
			ticket_id: ___ticket_props['ticket_id'],
			ticket_event: ___ticket_props['ticket_event'],
			ticket_type: ___ticket_props['ticket_type'],
			ticket_pr: ___ticket_props['ticket_pr']
		};
		this.setState({
			qrCode___found: true,
			qrCode___found___object: ___temp___ticket_object,
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	async ionViewDidEnter() : Promise<void> {
		await this.read_props___event();
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{

		return <>

			<MdCashDeskCheckoutModal isOpen={ this.state.qrCode___found } argument_object={ this.state.qrCode___found___object! } event___onDidDismiss={ this.handle___onQrCodeScan___dismiss_modal }/>
			<MdCashDeskErrorModal isOpen={ this.state.qrCode___result___error } event___onDidDismiss={() => { this.setState({ qrCode___result___error: false }); }}/>
			<MdCashDeskSuccessModal isOpen={ this.state.qrCode___result___success } event___onDidDismiss={() => { this.setState({ qrCode___result___success: false }); }}/>

			<IonPage>

				<IonHeader className="ion-no-border" collapse="fade">
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/dashboard"></IonBackButton>
						</IonButtons>
						<IonButtons slot="end">
							<IonButton onClick={() => { window.location.href = window.location.href.toString(); }}>
								<i className="fas fa-sync"></i>
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent style={{overflow:'hidden'}}>
					
					{(() => {
						switch (this.state.cashdesk___available) {
							case null: return <>
								<br />
								<br />
								<br />
								<ComSpinnerComponent size='standard'/>
							</>; break;
							case false: return <>
								<section className="sb-cashdesk-page---not-available">
									<br />
									<br />
									<br />
									<br />
									<br />
									<br />
									<br />
									<h4>Attenzione!</h4>
									<br />
									<p>questo evento non è disponibile per il cashdesk, la data non corrisponde alla data odierna</p>
									<br />
									<div>
										<button type="button" onClick={ this.handle___override___cashdesk_available } style={{color:'var(--color---light)'}}>
											<span>usa comunque il cashdesk</span>
										</button>
									</div>
									<br />
								</section>
							</>; break;
							case true: return <>
								<section className="sb-cashdesk-page---container">
									{(this.state.event___props___is_loading === false && this.state.event___props !== null) ? <>
										<ComEventMicroComponent event___props={ this.state.event___props }/>
										{(() => {
											switch (this.state.section___selected) {
												case 'scanner': return <>
													<section className="sb-cashdesk-page---qr-camera">
														<IonCard>
															<Scanner 
																paused={ this.state.section___selected !== 'scanner' || this.state.qrCode___found === true}
																formats={ [ 'qr_code' ] as BarcodeFormat[] }
																constraints={{ facingMode: 'environment' }}
																allowMultiple={ true }
																onScan={ this.handle___onQrCodeScan }
																classNames={{ container: 'sb-cashdesk-page---qr-camera---container', video: 'sb-cashdesk-page---qr-camera---video'}}
														//		components={{ audio: false }}
															/>
															<div className="sb-cashdesk-page---qr-camera---border-marker">
																<img src="assets/gui/scanner.svg"/>
															</div>
														</IonCard>
													</section>
												</>; break;
												case 'list': return <>
													{(this.state.tickets___collection___is_loading === true) ? <>
														<br />
														<br />
														<br />
														<ComSpinnerComponent size='standard'/>
													</> : <>
														<section className="sb-cashdesk-page---list">
															<section className="sb-cashdesk-pag---options-selector">
																<IonSelect key="view___" interface="popover" label="Visualizza" placeholder="Solo non usati" onIonChange={ this.handle___list___change_viewable }>
																	<IonSelectOption key="view___all" value="all">Tutti i Ticket</IonSelectOption>
																	<IonSelectOption key="view___unused" value="unused">Solo non usati</IonSelectOption>
																</IonSelect>
															</section>
															<section id="sb-cashdesk-page---list-container" className="sb-cashdesk-page---list-container">
																<IonList>
																	{this.state.tickets___collection.map((___tfl_l: any, ___tfl_l___idx: number) => {
																		return <>
																			<IonListHeader key={ ___tfl_l___idx } id={ 'sb-cashdesk-page---list-element---' + ___tfl_l['letter'].toLowerCase() }>
																				<IonLabel>{ ___tfl_l['letter'] }</IonLabel>
																			</IonListHeader>
																			{___tfl_l['tickets'].map((___tfl_l_t: any, ___tfl_l_t___idx: number) => {
																				return <>
																					{(
																						(this.state.section___selected___list_view === 'all') ||
																						(this.state.section___selected___list_view === 'unused' && (!(___tfl_l_t['ticket_used'] === true) || !___tfl_l_t['ticket_used']))
																					) ? <>
																						<IonItem key={ ___tfl_l___idx + ___tfl_l_t___idx } onClick={() => { this.handle___list___onTicketCollectionItemClick(___tfl_l_t); }} style={{paddingRight:'15pt'}}>
																							<IonLabel>{ ___tfl_l_t['ticket_person_name'] }</IonLabel>
																							{(___tfl_l_t['ticket_pr_props']) ? <>
																								<IonBadge color="medium" slot="end">
																									{(___tfl_l_t['ticket_pr_props']['user_name_displayed'] !== null && ___tfl_l_t['ticket_pr_props']['user_name_displayed'] !== '') ? <>
																										<span>{ ___tfl_l_t['ticket_pr_props']['user_name_displayed'] }</span>
																									</> : <>
																										<span>{ ___tfl_l_t['ticket_pr_props']['user_name'] + ' ' + ___tfl_l_t['ticket_pr_props']['user_surname'] }</span>
																									</>}
																								</IonBadge>
																								{(___tfl_l_t['ticket_used']) ? <>
																									<IonBadge color="success" slot="end" style={{marginLeft:'5pt'}}>
																										<i className="fas fa-check"></i>
																									</IonBadge>
																								</> : <></>}
																							</> : <>
																								{/**
																								 * 
																								 * 
																								 * 
																								 * 	aggiungere tipo di ticket eventualmente
																								 * 
																								 * 
																								 * 
																								 */}
																							</>}
																						</IonItem>
																					</> : <></>}
																				</>;
																			})}
																		</>;
																	})}
																</IonList>
															</section>
															<section className="sb-cashdesk-page---list-scroller">
																{this.state.tickets___collection.map((___tfl_p: any, ___tfl_p___idx: number) => {
																	return <>
																		<div key={ ___tfl_p___idx } onClick={() => { this.handle___list___select_letter(___tfl_p['letter']); }}>
																			<span>{ ___tfl_p['letter'] }</span>
																		</div>
																	</>;
																})}
															</section>
														</section>
													</>}
												</>; break;
											}
										})()}
									</> : <>
										<br />
										<br />
										<br />
										<ComSpinnerComponent size='standard'/>
									</>}
								</section>
							</>; break;
						}
					})()}
				</IonContent>

				{(this.state.cashdesk___available === true) ? <>
					<IonFooter className="sb-cashdesk-page---segment-selector">
						<IonToolbar>
							<IonSegment value={ this.state.section___selected } onIonChange={(___e: any) => { this.setState({ section___selected: ___e.detail.value }); }}>
								<IonSegmentButton value="scanner">
									<IonLabel>QR Code</IonLabel>
								</IonSegmentButton>
								<IonSegmentButton value="list">
									<IonLabel>Cerca Nome</IonLabel>
								</IonSegmentButton>
							</IonSegment>
						</IonToolbar>
					</IonFooter>
				</> : <></>}

			</IonPage>
			

		</>;

	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbCashdeskPage);