//	S T A T E

export interface state___SbInfoAppPage {

	version___version: string,
	version___build: string,

}

//	S T A T E   D E F A U L T

export const state___SbInfoAppPage___default: state___SbInfoAppPage = {

	version___version: '×.×.×',
	version___build: '×××××',
}